import { CoursewareStore } from './courseware/courseware.models';

export const PERSIST_KEY = 'globalAppState';

export const reduxStateDomains = {
  ROUTER_STATE: 'router', // TODO: See if we can safely change this name
  COURSEWARE_STATE: 'coursewareState',
};

export interface RootState {
  'router': History;
  'coursewareState': CoursewareStore;
}

export const restoreStateKeys: Record<keyof CoursewareStore, boolean> = {
  userHistory: true,
  userHistoryStateCompletedRequests: true,
  courseSectionId: true,
  currentCourse: true,
  errors: true,
  evolveProducts: true,
  isbns: true,
  vantageIsbns: true,
  isCourseOwner: true,
  linkData: true,
  messages: true,
  pendingRequestCount: false,
  roleId: true,
  userCourseOwnerRecords: true,
  userId: true,
  users: true,
  catalog: true,
  primaryTaxonomies: true,
  taxonomies: true,
  assignments: false,
  assessmentSubmissionsMap: true,
  checkedSyllabusItemIds: true,
  syllabusFolderInfo: true,
  courseBuilderState: true,
  externalEntities: true,
  isBatchEditModeEnabled: true,
  isDragDropModeEnabled: true,
  batchEditSelectedSyllabusItems: true,
  batchEditUpdatedSyllabusItems: false,
  // Don't turn this on in the future because we want to re-fetch syllabus items on page refresh
  syllabusItems: false,
  simulationAssignment: true,
  crosswalkUsers: false,
  registeredToken: true,
  // Items below here were added on the assumption they were missed by accident
  evolveUser: true,
  featureFlagsGrouped: true,
  eolsUser: true,
  assessmentStartTimeMap: true,
  catalogPage: false,
  sherpathLessonEditorPage: false,
  simulationEditorPage: false,
  skillEditorPage: false,
  evolveResourcesEditorPage: false,
  shadowHealthEditorPage: false,
  ebookEditorPage: false,
  appLinkCookies: true,
  collapsedFolderIds: true,
  skillSubmissionRecords: false,
  skillStaticData: false,
  userEngagementReport: true,
  moduleSequenceMap: true,
  assessments: true,
  enableDeepLink: true,
  coursePlanPage: true,
  sherpathPowerpointEditorPage: true,
  sherpathGroupActivityEditorPage: true,
  sherpathCaseStudyEditorPage: true,
  osmosisVideoEditorPage: true,
  osmosisVideoPlayerPage: true,
  groupActivity: true,
  hasRunAuthessHealthCheck: true,
  osmosisTokenDto: true,
  migratedEntitlements: true,
  abTestFlavors: true,
  progressIndicatorValues: false,
  isShowShadowHealthOrientationBanner: true,
  isAppLinkAssignmentCreateFlow: true,
  appLinkAssignmentStudents: true,
  isChatBotCanaryFlagChecked: true,
  collapsedFilterTitles: true
};

// Anything we do not plan to restore later can be skipped on storage in the first place
export const omitStateKeys: Partial<Record<keyof CoursewareStore, boolean>> = Object.keys(restoreStateKeys).reduce((acc, key) => {
  if (restoreStateKeys[key]) {
    return acc;
  }
  return {
    ...acc,
    [key]: true
  };
}, {});
