import {
  get,
  has,
  isEmpty,
  isNil,
  keyBy,
  orderBy,
  partition,
  times,
  uniq,
  uniqBy
} from 'lodash';
import { decode } from 'html-entities';
import { ELSModalConstant } from '@els/els-component-modal-react';
import React from 'react';
import moment from 'moment';
import {
  RecContentItemDto,
  RecContentItemTypeDto,
  RecJsonApiDataItem,
  RecTaxonomyNodeAttributesNodeTypeDto,
  RecTaxonomyNodeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';
import {
  ALL_OPTION_NAME,
  ALL_OPTION_VALUE,
  CatalogViewType,
  contentBaseURL,
  EbookPageRangeSeparators,
  EvolveResourceAssetTypeDisplayMap,
  evolveResourceTypeSortMap,
  RecContentItemTitleHandlerMap,
  recContentItemTypeDtoToContentTypeMap,
  RecContentTypeSortOrder,
  RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS,
  SequenceMappedTypes,
  SyllabusFolderOptionType
} from './catalog.constants';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { ActiveSyllabusItemTypeDto, } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { getSortedSyllabusTreeMapItems } from '../course-builder/courseBuilder.utilities';
import {
  ELSCommonConfig,
  ELSDropDownOption,
  ELSLoggingService
} from '../../components/els.components';
import {
  AppAction,
  Application
} from '../../apis/eols-app-link/eols-app-link.constants';
import { RoutePath } from '../../components/app/app.constants';
import {
  addSearchParams,
  SELECT_OPTION
} from '../../utilities/app.utilities';
import {
  CatalogAdaptiveLessonExternalEntityDto,
  CatalogEvolveResourceExternalEntityDtoParsed,
  CatalogEvolveResourcePermission,
  CatalogExternalEntityDtoParsed,
  CatalogInteractiveReviewExternalEntityDtoParsed,
  CatalogOsmosisVideoExternalEntityDtoParsed,
  CatalogShadowHealthExternalEntityDtoParsed,
  CatalogSherpathCaseStudyExternalEntityDtoParsed,
  CatalogSherpathGroupActivityExternalEntityDtoParsed,
  CatalogSherpathLessonExternalEntityDtoParsed,
  CatalogSherpathPowerpointExternalEntityDtoParsed,
  CatalogSherpathSimulationExternalEntityDtoParsed,
  CatalogSherpathSkillExternalEntityDtoParsed,
  CatalogSimChartExternalEntityDtoParsed,
  CatalogWithExternalEntitiesDto,
  OsmosisTokenDto,
  SherpathModuleExternalEntityDto,
  SherpathModuleExternalEntityDtoParsed,
  UserDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  filterBySearchQuery,
  flattenTree,
  isInstructor,
  isStudent,
  mapToIds,
  removeOuterQuotes,
  stripNilProps,
  truncateTitle
} from '../../utilities/common.utilities';
import { AssignmentEditorSearchParam } from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.constants';
import {
  BundleMemberProductDto,
  BundleMemberProductTypeKeyDto,
  EvolveProductDto,
  EvolveProductType,
  EvolveProductTypeKey
} from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import {
  getBooleanFromGroupFeatureFlagWithFallbackToGlobal,
  getFeatureFlagGroups
} from '../../utilities/featureFlag.utilities';
import {
  FALSE_VALUE,
  FEATURE_FLAG,
  TRUE_VALUE
} from '../../apis/eols-features-api/eols-features-api.constants';
import {
  getActionsFromEvolveProducts,
  getEvolveProductsForAction,
  getEvolveProductTitleByIsbn,
  getHesiTaxonIds,
  getIncludesDisplay,
  getIsbnFromEbookCatalogItemId,
  getPageRangesFromEbookCatalogItemId,
  getTaxonsTitle
} from '../course-plan/syllabus.utilities';
import {
  PrimaryTaxonomy,
  PrimaryTaxonomyDto,
  PrimaryTaxonomyTaxonDto
} from '../../apis/rec-gateway/rec-gateway.models';
import {
  getBookTaxonomy,
  getChapterPageRangesFromPageRanges,
  getEbookAssignmentTitle,
  getEbookComponents,
  getPageCountFromPageRanges
} from '../ebook-assignment-editor/ebook-assignment.utilities';
import ProductSelectModal, { ProductSelectModalId } from '../course-plan/ProductSelectModal.component';
import { ELSModalServiceType } from '../../models/els.models';
import {
  CatalogResourceStatus,
  CatalogResourceStatusMap,
  LessonSyllabusItemTypes,
  MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH
} from '../course-plan/syllabus.constants';
import {
  ActionMenuItemConfig,
  ActionMenuItemType
} from '../course-plan/syllabus.models';
import { SyllabusTreeMapItem } from '../course-builder/courseBuilder.models';
import { AssignmentDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { LocationWithQuery } from '../../redux/location/location.models';
import { getSelectedTaxonomiesFromEbookFilterState } from '../../components/ebook-filter/ebook-filter.utilities';
import { EbookFilterState } from '../../components/ebook-filter/ebook-filter.models';
import {
  CatalogItem,
  CatalogItemConfig,
  CatalogItemConfigMap,
  FolderViewItemType,
  ResourceStatusMap
} from './catalog.models';
import { LANGUAGE_KEY } from '../../translations/message.constants';
import {
  SyllabusItemAction,
  SyllabusItemTypeConfigMap,
  UnSupportedEvolveResourceTypeDEPRECATED
} from '../../constants/content-type.constants';
import { FeatureFlagsGroupedDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import { ServerConstants } from '../../components/app/server.constants';
import { NavigateToApp } from '../../redux/courseware/courseware.models';
import {
  CoursewareUserHistoryStateKey,
  EolsUserHistoryResponseDto
} from '../../apis/eols-user-crud/eols-user-crud.dtos';
import {
  ItemSequenceMap,
  SequenceItemDto,
  SequenceMap
} from '../../apis/ocs-api-service/ocs-api-service.dtos';
import { getHourAndMinuteFromSecond } from '../../utilities/unitConverter.utilities';
import { SherpathLessonOsmosisIncludesConfigs } from '../osmosis-video-editor/osmosis-video.constants';
import {
  ResourceFilterItem,
  ResourceFilterMap
} from '../../components/resource-filter/ResourceFilter.component';

const fileName = 'catalog.utilities';

export const getEbookNodeTitle = (node: Partial<PrimaryTaxonomyTaxonDto> | RecTaxonomyNodeDto) => {
  if (!node || !node.attributes) {
    return '';
  }
  return node.attributes.displayName || node.attributes.text || '';
};

export const getEbookTitle = (bookTaxonomy: PrimaryTaxonomyDto): string => {
  if (!bookTaxonomy || !bookTaxonomy.data || !bookTaxonomy.data[0]) {
    return '';
  }
  return getEbookNodeTitle(bookTaxonomy.data[0]);
};

export const getVbId = (evolveProduct: EvolveProductDto) => {
  if (!evolveProduct) {
    return null;
  }
  return evolveProduct.vbId || evolveProduct.vbID;
};

export const getTaxonomiesWithFlattenedBranchChildren = (
  fullList: RecTaxonomyNodeDto[],
  ids: string[],
  level = 1
): RecTaxonomyNodeDto[] => {
  return fullList
    .filter(x => {
      return ids.includes(x.id);
    })
    .sort((a, b) => {
      return a.attributes.displayOrder - b.attributes.displayOrder;
    })
    .reduce((acc, cur) => {
      if (get(cur, 'relationships.children.data.length')) {
        const childIds = cur.relationships.children.data.map(mapToIds);
        return [...acc, cur, ...getTaxonomiesWithFlattenedBranchChildren(fullList, childIds, level + 1)];
      }
      return [...acc, cur];
    }, []);
};

export const getTaxonomyFromIds = (catalog: CatalogWithExternalEntitiesDto, ids: string[]): RecTaxonomyNodeDto[] => {
  return catalog.catalog.included.filter((taxonomy) => ids.includes(taxonomy.id));
};

export const filterContentItemsByTaxonomyNodes = (contentItems: RecContentItemDto[], props: {
  catalog: CatalogWithExternalEntitiesDto;
  taxonomyIds: string[];
}): RecContentItemDto[] => {

  const {
    catalog,
    taxonomyIds
  } = props;

  if (!contentItems || !contentItems.length || !taxonomyIds || !taxonomyIds.length) {
    return contentItems;
  }

  const nodeIds = getTaxonomiesWithFlattenedBranchChildren(
    catalog.catalog.included,
    taxonomyIds
  ).map(mapToIds);
  return contentItems.filter((item) => {
    return item.relationships.taxonomies.data.find((relationship) => {
      return nodeIds.includes(relationship.id);
    });
  });
};

export const filterContentItemsByEbookFilterState = (contentItems: RecContentItemDto[], props: {
  catalog: CatalogWithExternalEntitiesDto;
  ebookFilterState: EbookFilterState;
}): RecContentItemDto[] => {
  const {
    catalog,
    ebookFilterState
  } = props;

  if (!contentItems || !contentItems.length || !ebookFilterState) {
    return contentItems;
  }

  const selectedTaxonomies = getSelectedTaxonomiesFromEbookFilterState(ebookFilterState);

  if (!selectedTaxonomies || !selectedTaxonomies.length) {
    return contentItems;
  }

  return filterContentItemsByTaxonomyNodes(contentItems, {
    catalog,
    taxonomyIds: selectedTaxonomies
  });

};

export const filterContentItemsByHesiChapterFilter = (contentItems: RecContentItemDto[], props: {
  catalog: CatalogWithExternalEntitiesDto;
  isHesiFocusChapterFilterEnabled: boolean;
}): RecContentItemDto[] => {
  const {
    catalog,
    isHesiFocusChapterFilterEnabled
  } = props;

  if (!contentItems || !contentItems.length || !isHesiFocusChapterFilterEnabled) {
    return contentItems;
  }

  const taxonomyIds = getHesiTaxonIds(props.catalog);
  return filterContentItemsByTaxonomyNodes(contentItems, {
    catalog,
    taxonomyIds
  });
};

export const getContentItemsByTaxonomies = (catalog: CatalogWithExternalEntitiesDto, taxonomies: RecTaxonomyNodeDto[]) => {

  if (!taxonomies || !taxonomies.length) {
    return [];
  }

  return filterContentItemsByTaxonomyNodes(catalog.catalog.data, {
    catalog,
    taxonomyIds: taxonomies.map(mapToIds)
  });
};

export const getContentItemsByTaxonomyNodes = (
  catalog: CatalogWithExternalEntitiesDto,
  taxonomyIds: string[]
): RecContentItemDto[] => {
  if (!taxonomyIds) {
    return [];
  }
  const taxonomies = getTaxonomyFromIds(catalog, taxonomyIds);
  if (!taxonomies) {
    return [];
  }
  return getContentItemsByTaxonomies(catalog, taxonomies);
};

export const isSelectAll = (
  contentItems: RecContentItemDto[],
  checkedContentItemsMap: object
) => {
  if (!contentItems || !contentItems.length) {
    return false;
  }
  const uncheckedItem = contentItems.find((contentItem) => !checkedContentItemsMap[contentItem.id]);
  return !uncheckedItem;
};

export const getSherpathModuleParsed = (externalEntity: SherpathModuleExternalEntityDto): SherpathModuleExternalEntityDtoParsed => {

  if (!externalEntity || !externalEntity.entity) {
    return {
      ...externalEntity,
      _parsedData: null
    };
  }

  let _parsedData;

  try {
    _parsedData = JSON.parse(externalEntity.entity);
  } catch (e) {
    _parsedData = {
      title: `${RecContentItemTypeDto.SHERPATH_MODULE} - ${externalEntity.catalogItemId}`
    };
    ELSLoggingService.error(
      fileName,
      `Unable to parse external entity '${externalEntity.catalogItemId}`,
    );
  }

  return {
    ...externalEntity,
    _parsedData
  };

};

export const getExternalEntity = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogExternalEntityDtoParsed => {

  if (!contentItem || !catalog.externalEntities) {
    return null;
  }

  const externalEntity = catalog.externalEntities.find((_externalEntity) => {
    return _externalEntity.catalogItemId === contentItem.id;
  });

  if (!externalEntity || !externalEntity.entity) {
    return null;
  }

  if ([
    RecContentItemTypeDto.SHERPATH_LESSON,
    RecContentItemTypeDto.EVOLVE_RESOURCE,
    RecContentItemTypeDto.SHADOW_HEALTH,
    RecContentItemTypeDto.SHERPATH_SIMULATION,
    RecContentItemTypeDto.SHERPATH_SKILL,
    RecContentItemTypeDto.SHERPATH_POWERPOINT,
    RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY,
    RecContentItemTypeDto.SHERPATH_CASE_STUDY,
    RecContentItemTypeDto.OSMOSIS_VIDEO,
    RecContentItemTypeDto.REVIEW_QUIZ,
  ].includes(contentItem.type)) {

    let _parsedData;

    const _externalEntity = externalEntity as
      CatalogSherpathLessonExternalEntityDtoParsed
      | CatalogEvolveResourceExternalEntityDtoParsed
      | CatalogShadowHealthExternalEntityDtoParsed
      | CatalogSherpathSimulationExternalEntityDtoParsed
      | CatalogSherpathSkillExternalEntityDtoParsed
      | CatalogSherpathCaseStudyExternalEntityDtoParsed
      | CatalogSherpathPowerpointExternalEntityDtoParsed
      | CatalogSherpathGroupActivityExternalEntityDtoParsed
      | CatalogOsmosisVideoExternalEntityDtoParsed
      | CatalogInteractiveReviewExternalEntityDtoParsed;

    try {
      _parsedData = JSON.parse(_externalEntity.entity.data);
    } catch (e) {
      _parsedData = {
        // Amazingly this fallback works because all these external entity types share a title property
        title: `${contentItem.type} - ${contentItem.id}`
      };
      ELSLoggingService.error(
        fileName,
        `Unable to parse external entity '${contentItem.id}`,
      );
    }

    return {
      ..._externalEntity,
      _parsedData
    };
  }

  if (contentItem.type === RecContentItemTypeDto.ADAPTIVE_LESSON) {
    return externalEntity as CatalogAdaptiveLessonExternalEntityDto;
  }

  if (contentItem.type === RecContentItemTypeDto.SIM_CHART) {
    let _parsedData;
    const _externalEntity = externalEntity as CatalogSimChartExternalEntityDtoParsed;

    try {
      _parsedData = JSON.parse(_externalEntity.entity);
    } catch (e) {
      _parsedData = {
        // Amazingly this fallback works because all these external entity types share a title property
        title: `${contentItem.type} - ${contentItem.id}`
      };
      ELSLoggingService.error(
        fileName,
        `Unable to parse external entity '${contentItem.id}`,
      );
    }

    return {
      ..._externalEntity,
      _parsedData
    };

  }

  return null;

};

export const getEvolveResource = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogEvolveResourceExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogEvolveResourceExternalEntityDtoParsed;
};

export const getSherpathGroupActivity = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathGroupActivityExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSherpathGroupActivityExternalEntityDtoParsed;
};

export const getSherpathPowerpoint = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathPowerpointExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_POWERPOINT) {
    return null;
  }

  const externalEntity = getExternalEntity(contentItem, catalog) as CatalogSherpathPowerpointExternalEntityDtoParsed;
  const { _parsedData } = externalEntity;
  const fullURL = new URL(_parsedData.url, contentBaseURL).href;
  return {
    ...externalEntity,
    _parsedData: {
      ..._parsedData,
      _fullURL: fullURL,
    }
  };
};

export const getSherpathCaseStudy = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathCaseStudyExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_CASE_STUDY) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSherpathCaseStudyExternalEntityDtoParsed;
};

export const getShadowHealth = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogShadowHealthExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHADOW_HEALTH) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogShadowHealthExternalEntityDtoParsed;
};

export const getOsmosisVideo = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogOsmosisVideoExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.OSMOSIS_VIDEO) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogOsmosisVideoExternalEntityDtoParsed;
};

export const getInteractiveReview = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogInteractiveReviewExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.REVIEW_QUIZ) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogInteractiveReviewExternalEntityDtoParsed;
};

export const isEvolveResourceSupported = (evolveResource: CatalogEvolveResourceExternalEntityDtoParsed, unSupportedEvolveResourceTypes: string[] = []): boolean => {
  if (!evolveResource || !evolveResource._parsedData) {
    return true;
  }

  // Fall back to the old way so as not to break production courses that do not yet have new assetType props
  if (!evolveResource._parsedData.assetType && evolveResource._parsedData.type) {
    const normalizedType = evolveResource._parsedData.type.trim().toLowerCase();
    const unSupportedEvolveResourceTypesDEPRECATED = [
      UnSupportedEvolveResourceTypeDEPRECATED.LMS.toLowerCase(),
      UnSupportedEvolveResourceTypeDEPRECATED.DOWNLOAD_BY_RESOURCE_TYPE.toLowerCase(),
      UnSupportedEvolveResourceTypeDEPRECATED.DOWNLOADS.toLowerCase(),
    ];
    return !unSupportedEvolveResourceTypesDEPRECATED.includes(normalizedType);
  }

  if (!unSupportedEvolveResourceTypes || !unSupportedEvolveResourceTypes.length || !evolveResource._parsedData.assetType) {
    return true;
  }

  const normalizedType = evolveResource._parsedData.assetType.trim();
  return !unSupportedEvolveResourceTypes.includes(normalizedType);
};

const bySupportedTypes = (catalog: CatalogWithExternalEntitiesDto, unSupportedEvolveResourceTypes: string[]) => (contentItemDto: RecContentItemDto) => {

  if ([
    RecContentItemTypeDto.SHERPATH_MODULE,
    RecContentItemTypeDto.HESI_EXAM,
    RecContentItemTypeDto.SHERPATH_TESTBANK,
  ].includes(contentItemDto.type)) {
    return false;
  }

  if (contentItemDto.type !== RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return true;
  }
  const evolveResource = getEvolveResource(contentItemDto, catalog);
  return isEvolveResourceSupported(evolveResource, unSupportedEvolveResourceTypes);
};

const byProduct = (isbn: string) => (contentItemDto: RecContentItemDto) => {
  if (!isbn || isbn === ALL_OPTION_VALUE) {
    return true;
  }

  return contentItemDto.attributes.isbn === isbn;
};

export const getContentItemsByStatus = (statusFilterState: Array<string>, contentItems: RecContentItemDto[], resourceStatusMap: ResourceStatusMap): RecContentItemDto[] => {
  return statusFilterState.reduce((acc, cur) => {
    let filteredContentItemsByStatus = [];
    if (cur === CatalogResourceStatusMap[CatalogResourceStatus.NOT_ADDED].value) {
      filteredContentItemsByStatus = contentItems.filter(contentItem => {
        return !resourceStatusMap[contentItem.id];
      });
    } else if (cur === CatalogResourceStatusMap[CatalogResourceStatus.VISIBLE].value) {
      filteredContentItemsByStatus = contentItems.filter(contentItem => {
        const visibleResources = resourceStatusMap[contentItem.id] && resourceStatusMap[contentItem.id][cur].length !== 0;
        const dueResources = resourceStatusMap[contentItem.id] && resourceStatusMap[contentItem.id].assigned.length !== 0;
        return visibleResources || dueResources;
      });
    } else if (cur === CatalogResourceStatusMap[CatalogResourceStatus.DUE].value) {
      filteredContentItemsByStatus = contentItems.filter(contentItem => {
        return resourceStatusMap[contentItem.id] && resourceStatusMap[contentItem.id].assigned.length !== 0;
      });
    } else {
      filteredContentItemsByStatus = contentItems.filter(contentItem => {
        return resourceStatusMap[contentItem.id]
          && resourceStatusMap[contentItem.id][cur]
          && resourceStatusMap[contentItem.id][cur].length !== 0;
      });
    }
    return filteredContentItemsByStatus.length !== 0 ? acc.concat(filteredContentItemsByStatus) : acc;
  }, []);
};

export const getNumberOfHiddenCheckedItem = (checkedItemIds: Array<string>, filteredItems: Array<RecContentItemDto | SyllabusItemDto>): number => {
  const hiddenCheckedSyllabusItems = checkedItemIds.filter(checkedItemId =>
    filteredItems.some(filteredItem => filteredItem.id === checkedItemId));

  return checkedItemIds.length - hiddenCheckedSyllabusItems.length;
};

export const getConfirmModalOnHiddenItem = ({ modalService, modalId, numberOfHiddenSyllabus, continueAction, messages, intl }) => {
  return modalService.openConfirmModal({
    modalId,
    content: (
      <>
        <h3>{messages.ARE_YOU_SURE}</h3>
        <p>
          {intl.formatMessage({
            id: LANGUAGE_KEY.CONFIRM_ACTION_ON_HIDDEN_SYLLABUS,
            defaultMessage: messages.CONFIRM_ACTION_ON_HIDDEN_SYLLABUS,
            description: ''
          }, { numberOfHiddenSyllabus })}
        </p>
      </>
    ),
    confirmHandler: async () => {
      await modalService.closeModal(modalId);
      return continueAction();
    },
    cancelHandler: () => modalService.closeModal(modalId)
  });
};

export const getTitleWithLevelDashes = (level, levelOffset = 0, title): string => {
  let dashes = '';
  times(level - levelOffset, () => {
    dashes += '-';
  });
  return `${dashes}${title}`;
};

export const getTaxonomyRootNodes = (catalog: CatalogWithExternalEntitiesDto): RecTaxonomyNodeDto[] => {
  return catalog.catalog.included.filter((taxonomy) => {
    return taxonomy.attributes.root === true;
  });
};

export const getTaxonomyBookNodes = (catalog: CatalogWithExternalEntitiesDto): RecTaxonomyNodeDto[] => {
  return catalog.catalog.included.filter((taxonomy) => {
    return taxonomy.attributes.nodeType === RecTaxonomyNodeAttributesNodeTypeDto.BOOK;
  });
};

export const getProductFilters = (evolveProducts: EvolveProductDto[]): ELSDropDownOption[] => {

  const productOptions = uniqBy(evolveProducts, x => x.isbn).map((evolveProduct): ELSDropDownOption => {
    return {
      name: `${evolveProduct.title} (ISBN: ${evolveProduct.isbn})`,
      value: evolveProduct.isbn
    };
  }).sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const allOption: ELSDropDownOption = {
    name: ALL_OPTION_NAME,
    value: ALL_OPTION_VALUE
  };

  productOptions.unshift(allOption);

  return productOptions;
};

export const getBookSectionFilters = (catalog: CatalogWithExternalEntitiesDto): ELSDropDownOption[] => {
  const bookTaxonomies: RecTaxonomyNodeDto[] = getTaxonomyBookNodes(catalog);
  const sectionFilterOptions = getTaxonomiesWithFlattenedBranchChildren(
    catalog.catalog.included,
    bookTaxonomies.map(x => x.id)
  ).map((item: RecTaxonomyNodeDto) => ({
    name: `${getTitleWithLevelDashes(item.attributes.level, 0, getEbookNodeTitle(item))}`,
    value: item.id
  }));
  return [{
    name: ALL_OPTION_NAME,
    value: ALL_OPTION_VALUE
  }].concat(sectionFilterOptions);
};

export const getSyllabusFoldersOptionList = (syllabusItems: SyllabusItemDto[]): ELSDropDownOption[] => {
  const folderOptionList = [SyllabusFolderOptionType.SELECT_FOLDER];
  const sortedSyllabusTreeMapItems = getSortedSyllabusTreeMapItems(syllabusItems);
  const levelIndicator = '– ';
  const folderOptions = sortedSyllabusTreeMapItems.filter(treeMapItem => treeMapItem.syllabusItem.type === ActiveSyllabusItemTypeDto.FOLDER);
  folderOptions.forEach(treeMapItem => {
    const { syllabusItem: { id, title }, level } = treeMapItem;
    return folderOptionList.push({
      value: id,
      name: `${levelIndicator.repeat(level)}${title}`,
    });
  });
  folderOptionList.push(SyllabusFolderOptionType.ADD_FOLDER);
  return folderOptionList;
};

export const getTimeEstimateInMinuteFromPageCount = (pageCount: number) => {
  return pageCount * 3 * 60;
};

export const getTimeEstimateInSecondFromPageRanges = (pageRanges: string) => {
  const pageCount = getPageCountFromPageRanges(pageRanges);
  return getTimeEstimateInMinuteFromPageCount(pageCount);
};

export const getTimeEstimateDisplayFromPageRanges = (pageRanges: string) => {
  const pageCount = getPageCountFromPageRanges(pageRanges);
  return `(${pageCount} ${pageCount === 1 ? 'page' : 'pages'}, est. ${getHourAndMinuteFromSecond(getTimeEstimateInMinuteFromPageCount(pageCount))})`;
};

export const getEbookReadingTitle = (contentItem: RecContentItemDto, primaryTaxonomies: PrimaryTaxonomy[]): string => {
  const pageRanges = getPageRangesFromEbookCatalogItemId(contentItem.id);
  const isbn = getIsbnFromEbookCatalogItemId(contentItem.id);
  const bookTaxonomy = getBookTaxonomy(isbn, primaryTaxonomies);
  if (!bookTaxonomy) {
    return null;
  }
  const chapterPageRangeMap = getChapterPageRangesFromPageRanges(pageRanges, bookTaxonomy);

  return getEbookAssignmentTitle(chapterPageRangeMap, bookTaxonomy, false);
};

export const getEbookReadingSubtitle = (contentItem: RecContentItemDto, primaryTaxonomies: PrimaryTaxonomy[]): string => {
  const isbn = getIsbnFromEbookCatalogItemId(contentItem.id);
  const bookTaxonomy = getBookTaxonomy(isbn, primaryTaxonomies);
  if (!bookTaxonomy) {
    return null;
  }
  return getEbookTitle(bookTaxonomy);
};

export const getEnhancedLesson = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogAdaptiveLessonExternalEntityDto => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.ADAPTIVE_LESSON) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogAdaptiveLessonExternalEntityDto;
};

export const getEvolveResourceType = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): ActiveSyllabusItemTypeDto => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return null;
  }
  const evolveResource = getEvolveResource(contentItem, catalog);
  if (
    evolveResource
    && evolveResource._parsedData
    && evolveResource._parsedData.permission
    && evolveResource._parsedData.permission.trim().toLowerCase() === CatalogEvolveResourcePermission.STUDENT
  ) {
    return ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE;
  }
  // Temporarily default to instructor type since students will not have external entity for instructor resources
  // This can be cleaned up once REC services send more specific types for Evolve Resources
  return ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE;
};

export const getSyllabusItemTypeFromCatalogItem = (recContentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): ActiveSyllabusItemTypeDto => {

  if (!recContentItem) {
    ELSLoggingService.warn(fileName, 'recContentItem not found');
    return null;
  }

  if (recContentItem.type === RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return getEvolveResourceType(recContentItem, catalog);
  }

  return recContentItemTypeDtoToContentTypeMap[recContentItem.type];
};

export const getActiveSyllabusItemTypeFromCatalog = (catalog: CatalogWithExternalEntitiesDto, userRole: string): ActiveSyllabusItemTypeDto[] => {
  return catalog.catalog.data.map((recContentItemDto) => {
    return getSyllabusItemTypeFromCatalogItem(recContentItemDto, catalog);
  }).filter((type) => {
    if (!type) {
      return false;
    }
    if (isInstructor(userRole)) {
      return true;
    }
    return isStudent(userRole) && (type === ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE || type === ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO);
  });
};

export const sortFilterMap = (resourceFilterMap: ResourceFilterMap): ResourceFilterMap => {

  const sortedItems: ResourceFilterItem[] = orderBy(Object.values(resourceFilterMap), [
    (item: ResourceFilterItem) => {
      const config = SyllabusItemTypeConfigMap[item.value];
      return config ? config.sortOrder : 0;
    }, (item: ResourceFilterItem) => {
      if (!item.display) {
        return null;
      }
      return item.display.toLowerCase();
    }
  ]);

  return sortedItems.reduce((acc, item) => {
    return {
      ...acc,
      [item.value]: {
        ...item,
        children: item.children ? sortFilterMap(item.children) : null
      }
    };
  }, {});
};

export const isEvolveResourceType = (type: ActiveSyllabusItemTypeDto) => {
  return [
    ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE,
    ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE
  ].includes(type);
};

const getEvolveResourceAssetType = (evolveResource: CatalogEvolveResourceExternalEntityDtoParsed): string => {
  if (!evolveResource) {
    return null;
  }
  if (!evolveResource._parsedData.assetType && evolveResource._parsedData.type) {
    return evolveResource._parsedData.type;
  }
  return evolveResource._parsedData.assetType;
};

export const getFilterTypeDisplay = (type: string) => {
  if (!type) {
    return type;
  }
  let display = type;
  if (SyllabusItemTypeConfigMap[type]) {
    display = SyllabusItemTypeConfigMap[type].displayName;
  } else if (EvolveResourceAssetTypeDisplayMap[type]) {
    display = EvolveResourceAssetTypeDisplayMap[type];
  } else {
    display = type;
  }
  return display;
};
export const getFilterTypeFromContentItem = (
  recContentItemDto: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto
): {
  type: string;
  typeDisplay: string;
  parentType: string;
  parentDisplay: string;
} => {
  const syllabusItemType = getSyllabusItemTypeFromCatalogItem(recContentItemDto, catalog);
  if (!isEvolveResourceType(syllabusItemType)) {
    return {
      type: syllabusItemType,
      typeDisplay: getFilterTypeDisplay(syllabusItemType),
      parentType: null,
      parentDisplay: null,
    };
  }
  const evolveResource = getEvolveResource(recContentItemDto, catalog);
  const type = getEvolveResourceAssetType(evolveResource);
  const display = getFilterTypeDisplay(type);
  if (!type || !display) {
    return {
      type: syllabusItemType,
      typeDisplay: getFilterTypeDisplay(syllabusItemType),
      parentType: null,
      parentDisplay: null,
    };
  }
  return {
    type: `${syllabusItemType}_${display}`, // Use display here instead of type to combine different types with same display names
    typeDisplay: display,
    parentType: syllabusItemType,
    parentDisplay: getFilterTypeDisplay(syllabusItemType)
  };
};

export const getSupportedStudentCatalogTypes = (
  isIncludeSkills: boolean
): RecContentItemTypeDto[] => {

  const supportedRecTypes = [
    RecContentItemTypeDto.EVOLVE_RESOURCE,
    RecContentItemTypeDto.OSMOSIS_VIDEO,
  ];

  if (isIncludeSkills) {
    supportedRecTypes.push(RecContentItemTypeDto.SHERPATH_SKILL);
  }

  return supportedRecTypes;
};

export const getSupportedStudentCatalogTypeSyllabusTypes = (
  isIncludeSkills: boolean
): ActiveSyllabusItemTypeDto[] => {

  const studentTypes = getSupportedStudentCatalogTypes(isIncludeSkills);

  return studentTypes.map((recContentItemType) => {
    return recContentItemTypeDtoToContentTypeMap[recContentItemType];
  });
};

export const getTypeFilterOptionsForCatalog = (
  catalog: CatalogWithExternalEntitiesDto,
  userRole: string,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  courseSectionId: string,
  catalogItems?: RecContentItemDto[],
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ResourceFilterMap => {

  const isEnableSkillsInStudentView = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.ENABLE_SKILLS_IN_STUDENT_RESOURCES,
    courseSectionId
  ) : false;

  const studentTypes = getSupportedStudentCatalogTypeSyllabusTypes(isEnableSkillsInStudentView);

  const items = catalogItems || catalog.catalog.data;
  const isAddType = (resourceFilterMap: ResourceFilterMap, config: {
    type: string;
    parentType: string;
  }): boolean => {
    if (!config || !config.type) {
      return false;
    }
    if (isInstructor(userRole)) {
      return true;
    }
    return isStudent(userRole)
      && (
        config.parentType === ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE
        || studentTypes.includes(config.type as ActiveSyllabusItemTypeDto)
      );
  };

  const unSupportedEvolveResourceTypes = getFeatureFlagGroups(featureFlagsGrouped, FEATURE_FLAG.IS_UNSUPPORTED_EVOLVE_RESOURCE_TYPE);

  const resourceFilterMap = items
    .filter(bySupportedTypes(catalog, unSupportedEvolveResourceTypes))
    .reduce((acc, recContentItemDto): ResourceFilterMap => {
      const filterType = getFilterTypeFromContentItem(recContentItemDto, catalog);

      if (!isAddType(acc, filterType)) {
        return acc;
      }

      if (filterType.parentType) {
        const existingChildren = acc[filterType.parentType] ? acc[filterType.parentType].children : {};
        return {
          ...acc,
          [filterType.parentType]: {
            display: filterType.parentDisplay,
            value: filterType.parentType,
            children: {
              ...existingChildren,
              [filterType.type]: {
                display: filterType.typeDisplay,
                value: filterType.type,
                parent: filterType.parentType
              }
            }
          }
        };
      }

      return {
        ...acc,
        [filterType.type]: {
          display: filterType.typeDisplay,
          value: filterType.type,
        }
      };
    }, {});

  return sortFilterMap(resourceFilterMap);

};

export const isTypeInSelectedTypes = (
  selectedTypes: string[],
  filterType: string
): boolean => {

  if (!selectedTypes || !selectedTypes.length || !filterType) {
    return false;
  }

  if (LessonSyllabusItemTypes.includes(filterType as ActiveSyllabusItemTypeDto)) {
    return selectedTypes.some((selectedType) => {
      return LessonSyllabusItemTypes.includes(selectedType as ActiveSyllabusItemTypeDto);
    });
  }

  return selectedTypes.includes(filterType);
};

export const getContentItemsByTypes = (
  selectedTypes: string[],
  contentItems: RecContentItemDto[],
  catalog: CatalogWithExternalEntitiesDto
): RecContentItemDto[] => {

  if (!selectedTypes || !selectedTypes.length) {
    return contentItems;
  }

  return contentItems.filter((contentItem) => {
    const config = getFilterTypeFromContentItem(contentItem, catalog);
    if (!config) {
      return false;
    }
    return isTypeInSelectedTypes(selectedTypes, config.type) || isTypeInSelectedTypes(selectedTypes, config.parentType);
  });
};

export const byUserRole = (
  userRole: string,
  catalog: CatalogWithExternalEntitiesDto,
  isIncludeSkills: boolean
) => (contentItemDto: RecContentItemDto) => {

  if (isInstructor(userRole)) {
    return true;
  }

  const supportedRecTypes = getSupportedStudentCatalogTypes(isIncludeSkills);

  if (!supportedRecTypes.includes(contentItemDto.type)) {
    return false;
  }

  const type = getSyllabusItemTypeFromCatalogItem(contentItemDto, catalog);

  const supportedSyllabusTypes = [
    ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE,
    ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO,
  ];

  if (isIncludeSkills) {
    supportedSyllabusTypes.push(ActiveSyllabusItemTypeDto.SHERPATH_SKILL);
  }

  return supportedSyllabusTypes.includes(type);
};

const convertContentItemForSearchBox = (catalogItemConfigMap: CatalogItemConfigMap, contentItem: RecContentItemDto): string[] => {
  if (!catalogItemConfigMap) {
    return [];
  }
  const itemConfigMap = catalogItemConfigMap[contentItem.id];
  if (isNil(itemConfigMap)) {
    return [];
  }

  let itemConfigMapString = '';
  if (!isNil(itemConfigMap.title)) {
    itemConfigMapString = itemConfigMapString.concat(itemConfigMap.title).concat(' ');
  }
  if (!isNil(itemConfigMap.subtitle)) {
    itemConfigMapString = itemConfigMapString.concat(itemConfigMap.subtitle).concat(' ');
  }
  if (!isNil(itemConfigMap.includes) && Object.keys(itemConfigMap.includes).length > 0) {
    Object.keys(itemConfigMap.includes).forEach((key) => {
      itemConfigMapString = itemConfigMapString.concat(
        getIncludesDisplay({ prefix: 'Includes', key: key as ActiveSyllabusItemTypeDto, list: itemConfigMap.includes[key] })
      ).concat(' ');
    });
  }
  if (!isNil(itemConfigMap.includedIn) && Object.keys(itemConfigMap.includedIn).length > 0) {
    Object.keys(itemConfigMap.includedIn).forEach((key) => {
      itemConfigMapString = itemConfigMapString.concat(
        getIncludesDisplay({ prefix: 'Included in', key: key as ActiveSyllabusItemTypeDto, list: itemConfigMap.includedIn[key] })
      ).concat(' ');
    });
  }
  if (!isNil(itemConfigMap.type) && !isNil(SyllabusItemTypeConfigMap[itemConfigMap.type])) {
    itemConfigMapString = itemConfigMapString.concat(SyllabusItemTypeConfigMap[itemConfigMap.type].displayName);
  }

  return itemConfigMapString.toLowerCase().split(' ').map(item => item.trim());
};

export const getContentItemsBySearchResources = (
  catalogItemConfigMap: CatalogItemConfigMap,
  contentItems: RecContentItemDto[],
  searchQuery: string
): RecContentItemDto[] => {
  if (!catalogItemConfigMap || searchQuery === null || searchQuery === '') {
    return contentItems;
  }
  return contentItems.filter(contentItem => {
    const contentItemWords = convertContentItemForSearchBox(catalogItemConfigMap, contentItem);
    return filterBySearchQuery(searchQuery, contentItemWords);
  });
};

export const getFilteredContentItems = (config: {
  catalog: CatalogWithExternalEntitiesDto;
  selectedProduct: string;
  ebookFilterState: EbookFilterState;
  resourceTypeFilterState: ActiveSyllabusItemTypeDto[];
  resourceStatusFilterState: Array<string>;
  resourceStatusMap: ResourceStatusMap;
  catalogItemConfigMap: CatalogItemConfigMap;
  searchQuery: string;
  isHesiFocusChapterFilterEnabled: boolean;
  featureFlagsGrouped: FeatureFlagsGroupedDto[];
}): RecContentItemDto[] => {

  const {
    catalog,
    selectedProduct,
    ebookFilterState,
    resourceTypeFilterState,
    resourceStatusFilterState,
    resourceStatusMap,
    catalogItemConfigMap,
    searchQuery,
    isHesiFocusChapterFilterEnabled,
    featureFlagsGrouped
  } = config;

  let contentItems: RecContentItemDto[] = [];

  const unSupportedEvolveResourceTypes = getFeatureFlagGroups(featureFlagsGrouped, FEATURE_FLAG.IS_UNSUPPORTED_EVOLVE_RESOURCE_TYPE);

  contentItems = filterContentItemsByEbookFilterState(catalog.catalog.data, {
    catalog,
    ebookFilterState
  });

  contentItems = filterContentItemsByHesiChapterFilter(contentItems, {
    catalog,
    isHesiFocusChapterFilterEnabled
  });

  if (resourceStatusFilterState && resourceStatusFilterState.length !== 0 && contentItems) {
    contentItems = uniqBy(getContentItemsByStatus(resourceStatusFilterState, contentItems, resourceStatusMap), 'id');
  }

  if (!isEmpty(resourceTypeFilterState) && contentItems) {
    contentItems = getContentItemsByTypes(resourceTypeFilterState, contentItems, catalog);
  }

  const filteredContentItems = contentItems
    .filter(bySupportedTypes(catalog, unSupportedEvolveResourceTypes))
    .filter(byProduct(selectedProduct));

  return getContentItemsBySearchResources(catalogItemConfigMap, filteredContentItems, searchQuery);
};

const findTaxon = (itemChild: RecJsonApiDataItem, catalog: CatalogWithExternalEntitiesDto) => catalog.catalog.included.find(taxon => taxon.id === itemChild.id);

const getChildDisplayOrder = (itemChild: RecJsonApiDataItem, catalog: CatalogWithExternalEntitiesDto) => {
  const found = findTaxon(itemChild, catalog);
  return found ? found.attributes.displayOrder : 0;
};

const getItems = (taxon: RecTaxonomyNodeDto, items: RecContentItemDto[]): [RecContentItemDto[], RecContentItemDto[]] => {
  return partition(items, (item) => {
    return item.relationships.taxonomies.data.find(x => x.id === taxon.id);
  });
};

export const getSherpathLessonSequenceMap = (
  moduleSequenceMap: SequenceMap,
  dominantTaxon: RecTaxonomyNodeDto,
): Record<string, SequenceItemDto> => {

  if (!moduleSequenceMap) {
    return null;
  }

  const mapValues = Object.values(moduleSequenceMap);

  if (!mapValues || !mapValues.length) {
    return null;
  }

  // We sort here because in the event that a multi taxon mapped item
  // is being sorted we want to default to the first module sequence value
  const { sortedSequences, dominantModuleVtwId } = mapValues.filter((item) => {
    return item && item.sequenceDto && item.taxon;
  }).sort((a, b) => {
    return a.taxon.attributes.displayOrder - b.taxon.attributes.displayOrder;
  }).reduce((acc, cur) => {
    return {
      sortedSequences: [...acc.sortedSequences, cur.sequenceDto],
      dominantModuleVtwId: dominantTaxon && cur.taxon.id === dominantTaxon.id
        ? cur.sequenceDto.resourceVtwId
        : acc.dominantModuleVtwId
    };
  }, {
    sortedSequences: [],
    dominantModuleVtwId: null
  });

  return sortedSequences.reduce((acc, cur) => {
    if (!cur || !cur.sequence) {
      return acc;
    }
    return cur.sequence.reduce((_acc, _cur) => {
      // Again here when an item maps to multiple modules we leave the first
      // Unless a subsequence modules is the dominant module (selected for this sort context)
      if (!_acc[_cur.vtwId] || (dominantModuleVtwId && cur.resourceVtwId === dominantModuleVtwId)) {
        return {
          ..._acc,
          [_cur.vtwId]: _cur
        };
      }
      return _acc;
    }, acc);
  }, {});
};

export const sortCatalogItemsWithoutTaxonomy = (config: {
  items: RecContentItemDto[];
  catalog: CatalogWithExternalEntitiesDto;
  catalogItemConfigMap: CatalogItemConfigMap;
  itemSequenceMap: ItemSequenceMap;
}) => {

  //  DominantTaxon is here in case items are mapped to different taxons having different sort orders within each taxon mapping.
  //  In this case we need to choose a single sort order because in this function we do not duplicate content items for each taxon mapping.
  //  In the event dominantTaxon is null we will choose the first tax mapping for an item as its dominantTaxon.

  const {
    items,
    catalog,
    catalogItemConfigMap,
    itemSequenceMap,
  } = config;

  return orderBy(items, [
    (contentItem) => {
      return RecContentTypeSortOrder.indexOf(contentItem.type);
    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (contentItem) => {

      if (SequenceMappedTypes.includes(contentItem.type)) {
        if (!itemSequenceMap || !contentItem.attributes || !contentItem.attributes.contentId) {
          return null;
        }
        const sequenceItem = itemSequenceMap[contentItem.attributes.contentId];
        if (!sequenceItem) {
          return null;
        }
        return sequenceItem.sequenceIndex;
      }

      if (contentItem.type === RecContentItemTypeDto.ADAPTIVE_LESSON) {
        const adaptiveLesson = getEnhancedLesson(contentItem, catalog);
        if (!adaptiveLesson) {
          return null;
        }
        return adaptiveLesson.entity.attributes.displayOrder;
      }

      if (contentItem.type === RecContentItemTypeDto.SHADOW_HEALTH) {
        const shadowHealth = getShadowHealth(contentItem, catalog);
        if (!shadowHealth) {
          return null;
        }
        return shadowHealth._parsedData.order;
      }

      if (contentItem.type === RecContentItemTypeDto.EVOLVE_RESOURCE) {
        const evolveResource = getEvolveResource(contentItem, catalog);
        if (!evolveResource) {
          return null;
        }
        return evolveResourceTypeSortMap.indexOf(evolveResource._parsedData.permission.toLowerCase() as CatalogEvolveResourcePermission);
      }

      return null;
    },
    (contentItem) => {
      if (!catalogItemConfigMap || !catalogItemConfigMap[contentItem.id]) {
        return contentItem.id;
      }
      return catalogItemConfigMap[contentItem.id].title;
    },
  ]);
};

const getOrderedSectionWithItems = (props: {
  nextTaxon: RecTaxonomyNodeDto;
  items: RecContentItemDto[];
  catalog: CatalogWithExternalEntitiesDto;
  catalogItemConfigMap: CatalogItemConfigMap;
  moduleSequenceMap: SequenceMap;
  dominantTaxon: RecTaxonomyNodeDto;
}): {
  taxon: RecTaxonomyNodeDto;
  items: RecContentItemTypeDto[];
}[] => {

  // Note we do not want to filter out duplicate mappings in this function
  // Duplicate mappings should be handled upstream

  const {
    nextTaxon,
    items,
    catalog,
    catalogItemConfigMap,
    moduleSequenceMap,
    dominantTaxon
  } = props;

  if (!nextTaxon) {
    return [];
  }

  const taxonBranchRoot = dominantTaxon || nextTaxon;
  const branchTaxonIds = getTaxonomiesWithFlattenedBranchChildren(catalog.catalog.included, [taxonBranchRoot.id]).map(mapToIds);

  if (!nextTaxon.relationships.children) {
    return [];
  }

  return nextTaxon.relationships.children.data.sort((a, b) => {
    return getChildDisplayOrder(a, catalog) - getChildDisplayOrder(b, catalog);
  }).reduce((acc, cur) => {
    const taxon = findTaxon(cur, catalog);

    if (!branchTaxonIds.includes(taxon.id)) {
      // Here we make sure to only return items in a certain section of the taxonomy tree
      return acc;
    }

    const parts = getItems(taxon, items);

    const itemSequenceMap = getSherpathLessonSequenceMap(moduleSequenceMap, dominantTaxon);

    // Note: This sort is slightly expensive
    // Currently I do not see any way to avoid it
    const sortedItems = sortCatalogItemsWithoutTaxonomy({
      items: parts[0],
      catalog,
      catalogItemConfigMap,
      itemSequenceMap,
    });

    const section = {
      taxon,
      items: sortedItems,
    };

    if (taxon.relationships.children && taxon.relationships.children.data.length) {
      return [...acc, section, ...getOrderedSectionWithItems({
        ...props,
        nextTaxon: taxon
      })];
    }
    return [...acc, section];
  }, []);
};

export const getUniqueSortedPrimaryTaxonomies = (primaryTaxonomies: PrimaryTaxonomy[]): PrimaryTaxonomy[] => {
  if (!primaryTaxonomies || !primaryTaxonomies.length) {
    return primaryTaxonomies;
  }

  const uniqPrimaryTaxonomies = uniqBy(primaryTaxonomies, (primaryTaxonomy: PrimaryTaxonomy) => {
    return primaryTaxonomy.taxonomy.data[0].id;
  });

  return orderBy(uniqPrimaryTaxonomies, (primaryTaxonomy: PrimaryTaxonomy) => {
    return getEbookTitle(primaryTaxonomy.taxonomy);
  });
};

export const sortCatalogItems = (config: {
  items: RecContentItemDto[];
  sortedTaxonomyIds: string[];
  catalog: CatalogWithExternalEntitiesDto;
  catalogItemConfigMap: CatalogItemConfigMap;
  moduleSequenceMap: SequenceMap;
  dominantTaxon: RecTaxonomyNodeDto;
}): {
  sectionedItems: RecContentItemDto[];
  flattenedItems: RecContentItemDto[];
  remainingItems: RecContentItemDto[];
} => {

  const {
    items,
    catalog,
    catalogItemConfigMap,
    sortedTaxonomyIds,
    moduleSequenceMap,
    dominantTaxon
  } = config;

  const itemSequenceMap = getSherpathLessonSequenceMap(moduleSequenceMap, dominantTaxon);

  const sortedItemsWithoutTaxonomyChunking = sortCatalogItemsWithoutTaxonomy({
    items,
    catalog,
    catalogItemConfigMap,
    itemSequenceMap
  });

  if (!sortedTaxonomyIds || !sortedTaxonomyIds.length) {
    return {
      flattenedItems: sortedItemsWithoutTaxonomyChunking,
      sectionedItems: sortedItemsWithoutTaxonomyChunking,
      remainingItems: [],
    };
  }

  return sortedTaxonomyIds.reduce((acc, cur, idx, arr) => {

    const taxonomyRootNode = catalog.catalog.included.find((taxon) => {
      return taxon.id === cur;
    });

    const sectionedItemsWithTaxon = getOrderedSectionWithItems({
      nextTaxon: taxonomyRootNode,
      items: sortedItemsWithoutTaxonomyChunking,
      catalog,
      catalogItemConfigMap,
      moduleSequenceMap,
      dominantTaxon
    });

    let flattenedItems = uniqBy(sectionedItemsWithTaxon.reduce((allItems, section) => {
      return [...allItems, ...section.items];
    }, []) as RecContentItemDto[], 'id');

    let sectionedItems = sectionedItemsWithTaxon.reduce((allItems, section) => {
      return [...allItems, ...section.items];
    }, []);

    const remainingItems = acc.remainingItems.filter((remainingItem: RecContentItemDto) => {
      return !flattenedItems.find(x => remainingItem.id === x.id);
    });

    if (arr.length - 1 === idx && remainingItems.length) {
      flattenedItems = [...flattenedItems, ...remainingItems];
      sectionedItems = [...sectionedItems, ...remainingItems];
    }

    return {
      sectionedItems: [...acc.sectionedItems, ...sectionedItems],
      flattenedItems: [...acc.flattenedItems, ...flattenedItems],
      remainingItems
    };
  }, {
    sectionedItems: [],
    flattenedItems: [],
    remainingItems: sortedItemsWithoutTaxonomyChunking
  });
};

export const buildCatalogItemConfig = (config: Partial<CatalogItemConfig>): CatalogItemConfig => {
  return {
    title: null,
    subtitle: null,
    includes: null,
    includedIn: null,
    type: null,
    evolveAssetType: null,
    learningDuration: null,
    ...config
  };
};

export const getTypeFolderedContentItems = (
  props: {
    catalogItemConfigMap: CatalogItemConfigMap;
    contentItems: RecContentItemDto[];
    keyPrefix: string;
    level: number;
    collapsedFolderMap: Record<string, boolean>;
  }
): CatalogItem[] => {

  const {
    catalogItemConfigMap,
    contentItems,
    keyPrefix,
    level,
    collapsedFolderMap
  } = props;

  const bucketedItemsByType: Partial<Record<ActiveSyllabusItemTypeDto, RecContentItemDto[]>> = contentItems.reduce((acc, item) => {
    const config = catalogItemConfigMap[item.id];

    if (!config) {
      return acc;
    }

    return {
      ...acc,
      [config.type]: acc[config.type] ? [...acc[config.type], item] : [item]
    };
  }, {});

  const sortedTypes = orderBy(Object.keys(bucketedItemsByType), (type: ActiveSyllabusItemTypeDto) => {
    return SyllabusItemTypeConfigMap[type].sortOrder;
  });

  return sortedTypes.reduce((acc, type: ActiveSyllabusItemTypeDto) => {

    const title = level === 0 && type !== ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT
      ? `Other ${SyllabusItemTypeConfigMap[type].displayNamePlural}`
      : SyllabusItemTypeConfigMap[type].displayNamePlural;

    const typeFolder: CatalogItem = {
      node: null,
      item: null,
      key: `${keyPrefix}_${type}`,
      id: `${keyPrefix}_${type}`,
      config: buildCatalogItemConfig({
        title,
        type,
      }),
      type: FolderViewItemType.TYPE,
      level
    };

    const items = bucketedItemsByType[type].map((contentItem): CatalogItem => {
      return {
        node: null,
        item: contentItem,
        id: contentItem.id,
        key: `${typeFolder.key}_${contentItem.id}`,
        config: catalogItemConfigMap[contentItem.id],
        type: FolderViewItemType.ITEM,
        level: level + 1
      };
    });

    if (collapsedFolderMap && collapsedFolderMap[typeFolder.key]) {
      return [
        ...acc,
        typeFolder
      ];
    }

    return [
      ...acc,
      typeFolder,
      ...items
    ];
  }, []);
};

export const getChapterContentItems = (props: {
  node: RecTaxonomyNodeDto;
  catalog: CatalogWithExternalEntitiesDto;
  catalogItemConfigMap: CatalogItemConfigMap;
  filteredContentItems: RecContentItemDto[];
  collapsedFolderMap: Record<string, boolean>;
}): CatalogItem[] => {

  const {
    node,
    catalogItemConfigMap,
    filteredContentItems,
    collapsedFolderMap,
  } = props;

  const contentItems = filteredContentItems.filter((item) => {
    return item.relationships.taxonomies.data.some((dataItem) => {
      return dataItem.id === node.id;
    });
  });

  return getTypeFolderedContentItems({
    catalogItemConfigMap,
    contentItems,
    keyPrefix: node.id,
    level: 2,
    collapsedFolderMap
  });
};

export const getTaxonomyNodeSortedChildren = (node: RecTaxonomyNodeDto, catalog: CatalogWithExternalEntitiesDto): RecTaxonomyNodeDto[] => {
  if (!node.relationships.children) {
    return [];
  }
  const taxonomyChildrenNodes = node.relationships.children.data.map((child) => {
    return catalog.catalog.included.find((taxonomyNode) => {
      return taxonomyNode.id === child.id;
    });
  });

  return orderBy(taxonomyChildrenNodes, 'attributes.displayOrder');
};
// eslint-disable-next-line sonarjs/cognitive-complexity
export const getOrderedFilteredItemsInFolders = (config: GetOrderedFilteredItemsProps): CatalogItem[] => {

  const {
    catalog,
    primaryTaxonomies,
    catalogItemConfigMap,
    selectedProduct,
    ebookFilterState,
    resourceTypeFilterState,
    resourceStatusFilterState,
    resourceStatusMap,
    searchQuery,
    isHesiFocusChapterFilterEnabled,
    featureFlagsGrouped,
    userRole,
    collapsedFolderMap,
    courseSectionId,
  } = config;

  const isEnableSkillsInStudentView = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.ENABLE_SKILLS_IN_STUDENT_RESOURCES,
    courseSectionId
  ) : false;

  const filteredContentItems = getFilteredContentItems({
    catalog,
    selectedProduct,
    ebookFilterState,
    resourceTypeFilterState,
    resourceStatusFilterState,
    resourceStatusMap,
    catalogItemConfigMap,
    searchQuery,
    isHesiFocusChapterFilterEnabled,
    featureFlagsGrouped
  }).filter(byUserRole(userRole, catalog, isEnableSkillsInStudentView));
  // This byUserRole filter only applies here since this item might be added to student course plan by instructor

  const uniquePrimaryTaxonomies = getUniqueSortedPrimaryTaxonomies(primaryTaxonomies);

  let orderedFilteredContentItems = uniquePrimaryTaxonomies.reduce((acc, primaryTax): CatalogItem[] => {

    const bookNode = catalog.catalog.included.find((catalogNode) => {
      return catalogNode.id === primaryTax.taxonomy.data[0].id;
    });

    if (!bookNode) {
      return acc;
    }

    const chapterFolders = getTaxonomyNodeSortedChildren(bookNode, catalog).reduce((_acc, chapterNode): CatalogItem[] => {

      const chapterItems: CatalogItem[] = getChapterContentItems({
        node: chapterNode,
        catalog,
        catalogItemConfigMap,
        filteredContentItems,
        collapsedFolderMap
      });

      if (!chapterItems || !chapterItems.length) {
        return _acc;
      }

      const chapterFolder: CatalogItem = {
        node: chapterNode,
        item: null,
        config: buildCatalogItemConfig({
          title: getEbookNodeTitle(chapterNode),
          type: ActiveSyllabusItemTypeDto.FOLDER
        }),
        id: chapterNode.id,
        key: chapterNode.id,
        type: FolderViewItemType.NODE,
        level: 1
      };

      if (collapsedFolderMap && collapsedFolderMap[chapterFolder.key]) {
        return [
          ..._acc,
          chapterFolder
        ];
      }

      return [
        ..._acc,
        chapterFolder,
        ...chapterItems
      ];
    }, []);

    if (!chapterFolders || !chapterFolders.length) {
      return acc;
    }

    const bookFolder: CatalogItem = {
      node: bookNode,
      item: null,
      config: buildCatalogItemConfig({
        title: getEbookNodeTitle(bookNode),
        type: ActiveSyllabusItemTypeDto.FOLDER
      }),
      id: bookNode.id,
      key: bookNode.id,
      type: FolderViewItemType.NODE,
      level: 0
    };

    if (collapsedFolderMap && collapsedFolderMap[bookFolder.key]) {
      return [
        ...acc,
        bookFolder
      ];
    }

    return [
      ...acc,
      bookFolder,
      ...chapterFolders
    ];
  }, []);

  const notPrimaryCatalogItems = filteredContentItems.filter((item) => {
    return !item.relationships.taxonomies.data.some((relationship) => {
      return uniquePrimaryTaxonomies.some((primaryTax) => {
        return primaryTax.taxonomy.included.some((primeTaxon) => {
          return primeTaxon.id === relationship.id;
        });
      });
    });
  });

  if (notPrimaryCatalogItems && notPrimaryCatalogItems.length) {
    orderedFilteredContentItems = [
      ...orderedFilteredContentItems,
      ...getTypeFolderedContentItems({
        catalogItemConfigMap,
        contentItems: notPrimaryCatalogItems,
        keyPrefix: 'OTHER',
        level: 0,
        collapsedFolderMap
      })
    ];
  }

  return orderedFilteredContentItems;
};

export const getCatalogItemFromContentItem = (catalogItemConfigMap: CatalogItemConfigMap) => (contentItem: RecContentItemDto): CatalogItem => {
  return {
    node: null,
    item: contentItem,
    type: FolderViewItemType.ITEM,
    id: contentItem.id,
    key: contentItem.id,
    config: catalogItemConfigMap[contentItem.id],
    level: 0
  };
};

type GetOrderedFilteredItemsProps = {
  catalog: CatalogWithExternalEntitiesDto;
  selectedProduct: string;
  ebookFilterState: EbookFilterState;
  resourceTypeFilterState: ActiveSyllabusItemTypeDto[];
  resourceStatusFilterState: Array<string>;
  resourceStatusMap: ResourceStatusMap;
  userRole: string;
  catalogItemConfigMap: CatalogItemConfigMap;
  searchQuery: string;
  primaryTaxonomies: PrimaryTaxonomy[];
  isHesiFocusChapterFilterEnabled: boolean;
  courseSectionId: string;
  moduleSequenceMap: SequenceMap;
  featureFlagsGrouped: FeatureFlagsGroupedDto[];
  activeView: CatalogViewType;
  collapsedFolderMap: Record<string, boolean>;
}

export const getOrderedFilteredItemsList = (config: GetOrderedFilteredItemsProps): CatalogItem[] => {
  const {
    catalog,
    selectedProduct,
    ebookFilterState,
    resourceTypeFilterState,
    resourceStatusFilterState,
    resourceStatusMap,
    userRole,
    catalogItemConfigMap,
    searchQuery,
    primaryTaxonomies,
    isHesiFocusChapterFilterEnabled,
    moduleSequenceMap,
    featureFlagsGrouped,
    courseSectionId
  } = config;

  const isEnableSkillsInStudentView = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.ENABLE_SKILLS_IN_STUDENT_RESOURCES,
    courseSectionId
  ) : false;

  const filteredContentItems = getFilteredContentItems({
    catalog,
    selectedProduct,
    ebookFilterState,
    resourceTypeFilterState,
    resourceStatusFilterState,
    resourceStatusMap,
    catalogItemConfigMap,
    searchQuery,
    isHesiFocusChapterFilterEnabled,
    featureFlagsGrouped,
  }).filter(byUserRole(userRole, catalog, isEnableSkillsInStudentView));
  // This byUserRole filter only applies here since this item might be added to student course plan by instructor

  const orderedFilteredCatalogItems = sortCatalogItems({
    items: filteredContentItems,
    sortedTaxonomyIds: getUniqueSortedPrimaryTaxonomies(primaryTaxonomies).map(x => x.taxonomy.data[0].id),
    catalogItemConfigMap,
    catalog,
    moduleSequenceMap,
    dominantTaxon: null
  }).flattenedItems;

  return orderedFilteredCatalogItems.map(getCatalogItemFromContentItem(catalogItemConfigMap));
};

export const getOrderedFilteredSelectedItems = (
  orderedFilteredContentItems: CatalogItem[],
  checkedContentItemsMap: Record<string, boolean>,
  activeView: CatalogViewType
): CatalogItem[] => {
  if (activeView === CatalogViewType.FOLDER_VIEW) {
    return orderedFilteredContentItems.filter((item) => {
      if (item.type !== FolderViewItemType.ITEM) {
        return false;
      }
      return checkedContentItemsMap[item.item.id];
    });
  }
  return orderedFilteredContentItems.filter((item) => checkedContentItemsMap[item.id]);
};

export const getOrderedFilteredItems = (config: GetOrderedFilteredItemsProps): CatalogItem[] => {
  if (config.activeView === CatalogViewType.FOLDER_VIEW) {
    return getOrderedFilteredItemsInFolders(config);
  }
  return getOrderedFilteredItemsList(config);
};

export const getSherpathLesson = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathLessonExternalEntityDtoParsed => {
  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_LESSON) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSherpathLessonExternalEntityDtoParsed;
};

export const getSherpathSkill = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathSkillExternalEntityDtoParsed => {

  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_SKILL) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSherpathSkillExternalEntityDtoParsed;
};

export const getSherpathSimulation = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSherpathSimulationExternalEntityDtoParsed => {

  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SHERPATH_SIMULATION) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSherpathSimulationExternalEntityDtoParsed;
};

export const getSimchart = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): CatalogSimChartExternalEntityDtoParsed => {

  if (!contentItem || contentItem.type !== RecContentItemTypeDto.SIM_CHART) {
    return null;
  }
  return getExternalEntity(contentItem, catalog) as CatalogSimChartExternalEntityDtoParsed;
};

export const getEvolveAssetType = (contentItem: RecContentItemDto, catalog: CatalogWithExternalEntitiesDto): string => {
  if (!contentItem || !contentItem.type) {
    return null;
  }
  if (contentItem.type !== RecContentItemTypeDto.EVOLVE_RESOURCE) {
    return null;
  }
  const evolveResource = getEvolveResource(contentItem, catalog);
  if (!evolveResource) {
    return null;
  }
  return evolveResource._parsedData.assetType;
};

export const transformTitle = (title: string, maxLength: number): string => {
  if (!title) {
    return title;
  }
  return truncateTitle(decode(title.replace(/<\/?[^>]+(>|$)/g, '')), maxLength);
};

export const getTitle = (
  contentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  maxLength: number
  // eslint-disable-next-line sonarjs/cognitive-complexity
): string => {
  if (!contentItem || !contentItem.type) {
    return null;
  }

  const handler = RecContentItemTitleHandlerMap[contentItem.type];

  if (!handler) {
    return transformTitle(`${contentItem.type} - ${contentItem.id}`, maxLength);
  }

  const title = handler({
    contentItem,
    catalog,
    primaryTaxonomies,
    maxLength
  });

  if (!title) {
    return transformTitle(`${contentItem.type} - ${contentItem.id}`, maxLength);
  }

  return transformTitle(title, maxLength);
};

export const getSubtitle = (
  contentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  evolveProducts: EvolveProductDto[],
  maxLength: number
): string => {

  if (!contentItem) {
    return '';
  }

  if (contentItem.type === RecContentItemTypeDto.SHADOW_HEALTH) {
    return getEvolveProductTitleByIsbn(evolveProducts, contentItem.attributes.isbn);
  }

  if (contentItem.type === RecContentItemTypeDto.EBOOK_READING) {
    const ebookSubtitle = getEbookReadingSubtitle(contentItem, primaryTaxonomies);
    if (ebookSubtitle) {
      return transformTitle(ebookSubtitle, maxLength);
    }
  }

  if (!primaryTaxonomies || !primaryTaxonomies.length) {
    return '';
  }

  const relatedTaxons = contentItem.relationships.taxonomies.data.map(mapToIds);

  const subtitle = getTaxonsTitle(relatedTaxons, primaryTaxonomies, maxLength);

  if (!subtitle && contentItem.type === RecContentItemTypeDto.EVOLVE_RESOURCE) {
    const evolveResource = getEvolveResource(contentItem, catalog);
    if (evolveResource && evolveResource._parsedData && evolveResource._parsedData.subtitle) {
      return transformTitle(evolveResource._parsedData.subtitle, maxLength);
    }
  }
  return subtitle;
};

export const getOsmosisVideoDurationInSeconds = (duration: string): number => {
  const [min = 0, sec = 0] = duration.split(':');
  return Number(min) * 60 + Number(sec);
};

export const getSherpathLessonOsmosisIncludesConfigs = (
  sherpathLessonContentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  isOsmosisEnabled: boolean
): SherpathLessonOsmosisIncludesConfigs => {

  if (!isOsmosisEnabled) {
    return null;
  }

  if (!sherpathLessonContentItem || !sherpathLessonContentItem.type) {
    return null;
  }

  if (sherpathLessonContentItem.type === RecContentItemTypeDto.SHERPATH_LESSON) {
    const sherpathLesson = getSherpathLesson(sherpathLessonContentItem, catalog);

    if (
      sherpathLesson
      && sherpathLesson._parsedData
      && sherpathLesson._parsedData.osmosisVideos
      && sherpathLesson._parsedData.osmosisVideos.length > 0
    ) {
      return sherpathLesson._parsedData.osmosisVideos.reduce((acc, video) => {
        const osmosisVideoCatalogItem = catalog.catalog.data
          .filter((catalogItem) => {
            return catalogItem.type === RecContentItemTypeDto.OSMOSIS_VIDEO;
          })
          .find((catalogItem) => {
            return catalogItem.attributes.contentId === removeOuterQuotes(video.osmosisVideoExternalId);
          });
        if (!osmosisVideoCatalogItem) {
          return acc;
        }
        const osmosisVideo = getOsmosisVideo(osmosisVideoCatalogItem, catalog);
        if (!osmosisVideo) {
          return acc;
        }
        const { _parsedData } = osmosisVideo;
        const transformedOsmosisVideo = {
          ...osmosisVideo,
          _parsedData: {
            ..._parsedData,
            title: transformTitle(_parsedData.title, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH)
          }
        };
        return [
          ...acc,
          {
            ...video,
            _entity: transformedOsmosisVideo
          }
        ];
      }, []);
    }
    return null;
  }
  return null;
};

export const splitVideos = (videos: string): string[] => {
  if (!videos) {
    return [];
  }
  // splits string by comma unless comma is in between single quotes
  return videos.split(/,(?=(?:(?:[^']*'){2})*[^']*$)/);
};

export const getEnhancedLessonOsmosisIncludesConfigs = (
  enhancedLessonContentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  isOsmosisEnabled: boolean
  // eslint-disable-next-line sonarjs/cognitive-complexity
): CatalogOsmosisVideoExternalEntityDtoParsed[] => {

  if (!isOsmosisEnabled) {
    return null;
  }

  if (!enhancedLessonContentItem || !enhancedLessonContentItem.type) {
    return null;
  }

  if (enhancedLessonContentItem.type === RecContentItemTypeDto.ADAPTIVE_LESSON) {
    const enhancedLesson = getEnhancedLesson(enhancedLessonContentItem, catalog);

    if (
      enhancedLesson
      && enhancedLesson.entity
      && enhancedLesson.entity.attributes
      && enhancedLesson.entity.attributes.containsVideo
    ) {

      const containsVideos = splitVideos(enhancedLesson.entity.attributes.containsVideo);
      const containsVideosScrubbed = containsVideos.map((videoId) => {
        return removeOuterQuotes(videoId.trim());
      });

      const osmosisVideoCatalogItems = catalog.catalog.data
        .filter((catalogItem) => {
          if (catalogItem.type !== RecContentItemTypeDto.OSMOSIS_VIDEO) {
            return false;
          }
          return containsVideosScrubbed.includes(catalogItem.attributes.contentId);
        });

      if (!osmosisVideoCatalogItems || !osmosisVideoCatalogItems.length) {
        return null;
      }

      const osmosisVideos: CatalogOsmosisVideoExternalEntityDtoParsed[] = osmosisVideoCatalogItems.reduce((acc, osmosisVideoCatalogItem) => {
        const osmosisVideo = getOsmosisVideo(osmosisVideoCatalogItem, catalog);
        if (!osmosisVideo) {
          return acc;
        }
        return [
          ...acc,
          osmosisVideo
        ];
      }, [] as CatalogOsmosisVideoExternalEntityDtoParsed[]);

      if (!osmosisVideos || !osmosisVideos.length) {
        return null;
      }

      return osmosisVideos.map((osmosisVideo) => {
        const { _parsedData } = osmosisVideo;
        return {
          ...osmosisVideo,
          _parsedData: {
            ..._parsedData,
            title: transformTitle(_parsedData.title, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH)
          }
        };
      });
    }
    return null;
  }
  return null;
};

export const getContentItemIncludes = (
  contentItemWithIncludesText: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  isOsmosisEnabled: boolean
): Partial<Record<ActiveSyllabusItemTypeDto, string[]>> => {

  if (!isOsmosisEnabled) {
    return null;
  }

  if (!contentItemWithIncludesText || !contentItemWithIncludesText.type) {
    return null;
  }

  if (contentItemWithIncludesText.type === RecContentItemTypeDto.SHERPATH_LESSON) {
    const sherpathLessonOsmosisIncludesConfigs = getSherpathLessonOsmosisIncludesConfigs(contentItemWithIncludesText, catalog, isOsmosisEnabled);

    if (!sherpathLessonOsmosisIncludesConfigs || !sherpathLessonOsmosisIncludesConfigs.length) {
      return null;
    }

    return {
      [ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO]: sherpathLessonOsmosisIncludesConfigs.map((config) => {
        return `${config._entity._parsedData.title} (est. ${getHourAndMinuteFromSecond(getOsmosisVideoDurationInSeconds(config._entity._parsedData.videoDuration))})`;
      })
    };
  }
  if (contentItemWithIncludesText.type === RecContentItemTypeDto.ADAPTIVE_LESSON) {
    const enhancedLessonOsmosisIncludesConfigs = getEnhancedLessonOsmosisIncludesConfigs(contentItemWithIncludesText, catalog, isOsmosisEnabled);

    if (!enhancedLessonOsmosisIncludesConfigs || !enhancedLessonOsmosisIncludesConfigs.length) {
      return null;
    }

    return {
      [ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO]: enhancedLessonOsmosisIncludesConfigs.map((config) => {
        return `${config._parsedData.title} (est. ${getHourAndMinuteFromSecond(getOsmosisVideoDurationInSeconds(config._parsedData.videoDuration))})`;
      })
    };
  }
  return null;
};

export const getContentItemIncludedIn = (
  contentItemWithIncludedInText: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  isOsmosisEnabled: boolean
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Partial<Record<ActiveSyllabusItemTypeDto, string[]>> => {

  if (!isOsmosisEnabled) {
    return null;
  }

  if (!contentItemWithIncludedInText || !contentItemWithIncludedInText.type) {
    return null;
  }

  if (contentItemWithIncludedInText.type !== RecContentItemTypeDto.OSMOSIS_VIDEO) {
    return null;
  }

  const includesMap = catalog.catalog.data
    .filter((catalogItem) => {
      return catalogItem.type === RecContentItemTypeDto.SHERPATH_LESSON || catalogItem.type === RecContentItemTypeDto.ADAPTIVE_LESSON;
    })
    .reduce((acc, catalogItem) => {
      if (catalogItem.type === RecContentItemTypeDto.SHERPATH_LESSON) {
        const sherpathLesson = getSherpathLesson(catalogItem, catalog);

        if (
          !sherpathLesson
          || !sherpathLesson._parsedData
          || !sherpathLesson._parsedData.osmosisVideos
          || !sherpathLesson._parsedData.osmosisVideos.length
        ) {
          return acc;
        }

        if (
          !sherpathLesson._parsedData.osmosisVideos.some((video) => {
            return removeOuterQuotes(video.osmosisVideoExternalId) === contentItemWithIncludedInText.attributes.contentId;
          })
        ) {
          return acc;
        }

        const newTitle = transformTitle(sherpathLesson._parsedData.title, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH);

        return {
          ...acc,
          [ActiveSyllabusItemTypeDto.SHERPATH_LESSON]: acc[ActiveSyllabusItemTypeDto.SHERPATH_LESSON]
            ? [...acc[ActiveSyllabusItemTypeDto.SHERPATH_LESSON], newTitle]
            : [newTitle]
        };
      }
      if (catalogItem.type === RecContentItemTypeDto.ADAPTIVE_LESSON) {
        const enhancedLesson = getEnhancedLesson(catalogItem, catalog);

        if (
          !enhancedLesson
          || !enhancedLesson.entity
          || !enhancedLesson.entity.attributes
          || !enhancedLesson.entity.attributes.containsVideo
        ) {
          return acc;
        }

        const containsVideos = splitVideos(enhancedLesson.entity.attributes.containsVideo);

        if (
          !containsVideos.some((videoId) => {
            return removeOuterQuotes(videoId.trim()) === contentItemWithIncludedInText.attributes.contentId;
          })
        ) {
          return acc;
        }

        const newTitle = transformTitle(enhancedLesson.entity.attributes.displayName, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH);

        return {
          ...acc,
          [ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON]: acc[ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON]
            ? [...acc[ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON], newTitle]
            : [newTitle]
        };
      }
      return acc;
    }, {});

  if (isEmpty(includesMap)) {
    return null;
  }

  return includesMap;
};

export const getPaperBookCoverImageUrl = (isbn: string): string => {
  return `https://covers.elsevier.com/200fw/${isbn}.jpg`;
};

export const handleAddResourceButtonClickRedirect = (props: {
  action: SyllabusItemAction;
  courseSectionId: string;
  navigateToApp: NavigateToApp;
  parentSyllabusItem?: SyllabusItemDto;
  redirect: (path: string) => void;
  evolveProducts: EvolveProductDto[];
  selectedProduct: EvolveProductDto;
  location: LocationWithQuery;
  featureFlagsGrouped: FeatureFlagsGroupedDto[];
}) => {

  const {
    action,
    courseSectionId,
    navigateToApp,
    parentSyllabusItem,
    redirect,
    evolveProducts,
    selectedProduct,
    location,
    featureFlagsGrouped,
  } = props;

  const sharedEditorSearchParams = {
    [AssignmentEditorSearchParam.PARENT_SYLLABUS_ITEM_ID]: parentSyllabusItem ? parentSyllabusItem.id : null,
    [AssignmentEditorSearchParam.ISBN]: selectedProduct ? selectedProduct.isbn : null,
    [AssignmentEditorSearchParam.REF]: location.pathname,
  };

  const sharedAppLinkParams = stripNilProps({
    courseSectionId, // TODO: Remove this when AL team is ready
    selectedEntitlement: selectedProduct,
    parentSyllabusItemId: parentSyllabusItem ? parentSyllabusItem.id : null,
    courseEntitlements: evolveProducts, // TODO: Remove this when AL team is ready
  });

  switch (action) {
    case SyllabusItemAction.ADD_EBOOK_READING:
      redirect(addSearchParams(RoutePath.EBOOK_ASSIGNMENT_EDITOR, {
        ...sharedEditorSearchParams,
        [AssignmentEditorSearchParam.ISBN]: getVbId(selectedProduct)
        // Ebooks use the vital source isbn here as the identifier - maps to primary taxonomy rec response
      }));
      break;
    case SyllabusItemAction.ADD_EXTERNAL_LINK:
      redirect(addSearchParams(RoutePath.EXTERNAL_LINK_ASSIGNMENT_EDITOR, {
        ...sharedEditorSearchParams
      }));
      break;
    case SyllabusItemAction.ADD_ADAPTIVE_LESSON: {
      const appAction = AppAction.ASSIGNMENT_CREATE;
      navigateToApp({
        app: Application.KNOWLEDGECARD,
        action: appAction,
        body: sharedAppLinkParams
      });
      break;
    }
    case SyllabusItemAction.ADD_ASSESSMENT_BUILDER: {
      const appAction = AppAction.ASSIGNMENT_CREATE;
      navigateToApp({
        app: Application.EAB,
        action: appAction,
        body: sharedAppLinkParams,
        includeLinkHash: true
      });
      break;
    }
    case SyllabusItemAction.ADD_SIMCHART: {
      const appAction = AppAction.ASSIGNMENT_CREATE;
      navigateToApp({
        app: Application.SIMSNG,
        action: appAction,
        body: sharedAppLinkParams
      });
      break;
    }
    case SyllabusItemAction.ADD_ADAPTIVE_QUIZ: {
      const isEnableEAQAssignmentManagementApp = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
        featureFlagsGrouped,
        FEATURE_FLAG.ENABLE_EAQ_ASSIGNMENT_MANAGEMENT_APP_CREATE_EDIT,
        courseSectionId
      ) : false;
      const appAction = AppAction.ASSIGNMENT_CREATE;
      navigateToApp({
        app: isEnableEAQAssignmentManagementApp ? Application.ASSIGNMENT_MANAGEMENT : Application.EAQ,
        action: appAction,
        body: sharedAppLinkParams
      });
      break;
    }
    default:
      break;
  }
};

export const getProductSelectOptionTitle = (props: {
  evolveProducts: EvolveProductDto[];
  evolveProductOption: EvolveProductDto;
  primaryTaxonomies: PrimaryTaxonomy[];
}): string => {
  if (props.evolveProductOption.productTypeKey === EvolveProductTypeKey.SHERPATH_EBOOK_COMPONENT_NSS) {
    // Find the primary taxonomy for book
    const primaryTaxonomy = props.primaryTaxonomies.find((_primaryTaxonomy) => {
      return _primaryTaxonomy.isbn === getVbId(props.evolveProductOption);
    });
    if (primaryTaxonomy) {
      return getEbookTitle(primaryTaxonomy.taxonomy);
    }
  }
  if (props.evolveProductOption.productTypeKey === EvolveProductTypeKey.SHERPATH_COMPONENT_NSS) {
    // Find the parent product for EAQ sub components
    const parentEvolveProduct = props.evolveProducts.find((_evolveProduct) => {
      const evolveProductComponents: EvolveProductDto[] = flattenTree(_evolveProduct.components, 'components');
      return evolveProductComponents.find((__evolveProduct) => {
        return __evolveProduct.isbn === props.evolveProductOption.isbn;
      });
    });
    if (parentEvolveProduct) {
      return parentEvolveProduct.title;
    }
  }
  return props.evolveProductOption.title;
};

export const getPrimaryTaxonomiesFromEvolveProducts = (evolveProducts: EvolveProductDto[]): PrimaryTaxonomy[] => {

  const productTaxonomies: PrimaryTaxonomy[] = evolveProducts.map((product) => {
    return {
      isbn: product.isbn,
      isbnType: product.type,
      taxonomy: null
    };
  });

  const ebookTaxonomies: PrimaryTaxonomy[] = getEbookComponents(evolveProducts).map((evolveProduct) => {
    return {
      isbn: getVbId(evolveProduct),
      isbnType: EvolveProductType.EBOOK,
      taxonomy: null
    };
  });

  return [...ebookTaxonomies, ...productTaxonomies];
};

export const getProductSelectOptions = (props: {
  evolveProducts: EvolveProductDto[];
  evolveProductOptions: EvolveProductDto[];
  primaryTaxonomies: PrimaryTaxonomy[];
}): ELSDropDownOption[] => {
  const options = props.evolveProductOptions.map((product) => {
    return {
      name: getProductSelectOptionTitle({
        evolveProducts: props.evolveProducts,
        primaryTaxonomies: props.primaryTaxonomies,
        evolveProductOption: product
      }),
      value: product.isbn
    };
  });

  return [SELECT_OPTION, ...options];
};

export const handleAddResourceButtonClick = (props: {
  action: SyllabusItemAction;
  courseSectionId: string;
  evolveProducts: EvolveProductDto[];
  modalService: ELSModalServiceType;
  navigateToApp: NavigateToApp;
  parentSyllabusItem?: SyllabusItemDto;
  primaryTaxonomies: PrimaryTaxonomy[];
  redirect: (path: string) => void;
  location: LocationWithQuery;
  featureFlagsGrouped: FeatureFlagsGroupedDto[];
}) => {
  const {
    action,
    parentSyllabusItem,
    evolveProducts,
    modalService,
    primaryTaxonomies,
  } = props;

  if (parentSyllabusItem) {
    ELSLoggingService.info(fileName, `clicked ${action} to ${parentSyllabusItem.title}`);
  } else {
    ELSLoggingService.info(fileName, `clicked ${action}`);
  }

  const products = getEvolveProductsForAction(action, evolveProducts);

  if (!products.length) {
    ELSLoggingService.warn(fileName, 'Content type does not have any matching course product entitlements');
  }

  if (action === SyllabusItemAction.ADD_ADAPTIVE_QUIZ) {
    handleAddResourceButtonClickRedirect({
      ...props,
      selectedProduct: null
    });
    return;
  }

  if (action === SyllabusItemAction.ADD_SIMCHART) {
    handleAddResourceButtonClickRedirect({
      ...props,
      selectedProduct: null
    });
    return;
  }

  if (products.length > 1) {
    const handleProductSelect = (selectedIsbn) => {
      handleAddResourceButtonClickRedirect({
        ...props,
        selectedProduct: products.find(product => product.isbn === selectedIsbn)
      });
    };

    modalService.openCustomModal({
      color: ELSModalConstant.color.primary,
      content: <ProductSelectModal onClickConfirm={handleProductSelect}
                                   options={getProductSelectOptions({
                                     evolveProducts,
                                     evolveProductOptions: products,
                                     primaryTaxonomies,
                                   })} />,
      modalId: ProductSelectModalId,
      showClose: true,
      dialogAriaLabel: 'Select product'
    });

    return;
  }
  handleAddResourceButtonClickRedirect({
    ...props,
    selectedProduct: products[0]
  });

};

export const catalogContainsTypes = (catalog: CatalogWithExternalEntitiesDto, types: RecContentItemTypeDto[]) => {
  if (!catalog || !catalog.catalog || !catalog.catalog.data) {
    return false;
  }
  return catalog.catalog.data.some(item => types.includes(item.type));
};

export const getActiveOrderedAddActionConfigs = (
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  orderedItemOptions: ActionMenuItemConfig[],
  evolveProducts: EvolveProductDto[],
  courseSectionId: string,
  catalog: CatalogWithExternalEntitiesDto,
  userRole: string
): ActionMenuItemConfig[] => {

  const actionsFromEntitlements = getActionsFromEvolveProducts(evolveProducts);
  const activeSyllabusItemType = getActiveSyllabusItemTypeFromCatalog(catalog, userRole);
  const actionsFromCatalog = uniq(activeSyllabusItemType).map((contentType) => {
    return SyllabusItemTypeConfigMap[contentType].syllabusItemAction;
  });

  const trueFn = () => true;
  const falseFn = () => false;
  const actionsFromEntitlementsInclude = (action) => {
    return actionsFromEntitlements.includes(action);
  };
  const actionsFromCatalogInclude = (action) => {
    return actionsFromCatalog.includes(action);
  };

  const filterHandlerMap: Record<SyllabusItemAction, (action?: SyllabusItemAction) => boolean> = {
    [SyllabusItemAction.ADD_OSMOSIS_VIDEO]: (action) => {
      const enableOsmosisVideos = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
        featureFlagsGrouped,
        FEATURE_FLAG.ENABLE_OSMOSIS_VIDEOS,
        courseSectionId
      ) : false;
      if (!enableOsmosisVideos) {
        return false;
      }
      return actionsFromCatalogInclude(action);
    },
    [SyllabusItemAction.ADD_A_FOLDER]: trueFn,
    [SyllabusItemAction.ADD_ADAPTIVE_LESSON]: actionsFromCatalogInclude,
    [SyllabusItemAction.ADD_ADAPTIVE_QUIZ]: actionsFromEntitlementsInclude,
    [SyllabusItemAction.ADD_EBOOK_READING]: actionsFromEntitlementsInclude,
    [SyllabusItemAction.ADD_MORE_RESOURCES]: trueFn,
    [SyllabusItemAction.ADD_SHERPATH_LESSONS]: actionsFromCatalogInclude,
    [SyllabusItemAction.ADD_SHERPATH_SIMULATIONS]: (action) => {
      const isSherpathSimulationsAddBtnHidden = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
        featureFlagsGrouped,
        FEATURE_FLAG.IS_SHERPATH_SIMULATIONS_ADD_BTN_HIDDEN,
        courseSectionId
      ) : false;
      if (isSherpathSimulationsAddBtnHidden) {
        return false;
      }
      return actionsFromCatalogInclude(action);
    },
    [SyllabusItemAction.ADD_SHERPATH_SKILLS]: actionsFromCatalogInclude,
    [SyllabusItemAction.ADD_INTERACTIVE_REVIEW]: actionsFromCatalogInclude,
    [SyllabusItemAction.ADD_EVOLVE_INSTRUCTOR_RESOURCES]: actionsFromCatalogInclude,
    [SyllabusItemAction.ADD_EVOLVE_STUDENT_RESOURCES]: actionsFromCatalogInclude,
    [SyllabusItemAction.ADD_SHERPATH_GROUP_ACTIVITY]: actionsFromCatalogInclude,
    [SyllabusItemAction.ADD_SIMCHART]: actionsFromEntitlementsInclude,
    [SyllabusItemAction.ADD_SHADOW_HEALTH]: actionsFromEntitlementsInclude,
    [SyllabusItemAction.ADD_ASSESSMENT_BUILDER]: (action) => {
      return actionsFromEntitlementsInclude(action) || catalogContainsTypes(catalog, [RecContentItemTypeDto.SHERPATH_TESTBANK]);
    },
    [SyllabusItemAction.ADD_SHERPATH_POWERPOINT]: actionsFromCatalogInclude,
    [SyllabusItemAction.ADD_SHERPATH_CASE_STUDY]: actionsFromCatalogInclude,
    [SyllabusItemAction.ADD_EXTERNAL_LINK]: () => {
      return featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
        featureFlagsGrouped,
        FEATURE_FLAG.IS_ADD_EXTERNAL_LINK_ACTION_ENABLED,
        courseSectionId
      ) : false;
    },
    [SyllabusItemAction.BULK_MOVE_REORDER]: trueFn,
    [SyllabusItemAction.BULK_REMOVE]: trueFn,
    [SyllabusItemAction.BULK_UNASSIGN]: trueFn,
    [SyllabusItemAction.BULK_EDIT_SETTING]: trueFn,
    [SyllabusItemAction.BULK_MAKE_VISIBLE_NOW]: trueFn,
    [SyllabusItemAction.DEV_ADD_ASSIGNMENT]: trueFn,
    [SyllabusItemAction.MOVE]: trueFn,
    [SyllabusItemAction.REMOVE]: trueFn,
    [SyllabusItemAction.RENAME]: trueFn,
    [SyllabusItemAction.SETTINGS]: trueFn,
    [SyllabusItemAction.UNASSIGN]: trueFn,
    [SyllabusItemAction.VIEW]: trueFn,
    [SyllabusItemAction.MAKE_VISIBLE_NOW]: trueFn,
    [SyllabusItemAction.VIEW_PERFORMANCE]: trueFn,
    [SyllabusItemAction.CREATE_DEEP_LINK]: trueFn,
    [SyllabusItemAction.DESELECT_ALL]: falseFn,
    [SyllabusItemAction.SELECT_ALL]: falseFn,
  };

  return orderedItemOptions
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .filter((config: ActionMenuItemConfig) => {

      const { action, type } = config;

      if (type !== ActionMenuItemType.ACTION) {
        return true;
      }

      if (!filterHandlerMap[action]) {
        return false;
      }

      return filterHandlerMap[action](action);

    }).reduce((acc, cur, idx, arr) => {
      // This is to remove the second less add button in the event the course has both types
      // We can do this because we handle both types the same in the resource library
      if (cur.action === SyllabusItemAction.ADD_SHERPATH_LESSONS) {
        const otherLessonAction = arr.find((actionMenuItemConfig) => {
          return actionMenuItemConfig.action === SyllabusItemAction.ADD_ADAPTIVE_LESSON;
        });
        if (otherLessonAction) {
          return acc;
        }
      }
      return [...acc, cur];
    }, []);
};

export const getContentItemIdFromAssignment = (assignment: AssignmentDto): string => {

  if (!assignment) {
    return null;
  }

  if (assignment.contentId) {
    return `${assignment.isbn}/${assignment.contentId}`;
  }

  // This is for backwards compatibility and can be removed
  if (!assignment.assignmentGoals) {
    return null;
  }

  const contentItemGoal = assignment.assignmentGoals.find(assignmentGoal => assignmentGoal.goal !== 0);
  const contentItemId = contentItemGoal ? contentItemGoal.vtwId : '';

  return `${assignment.isbn}/${contentItemId}`;
};

export const getSortedSyllabusFolderOrderMap = (treeMapItems: SyllabusTreeMapItem[]): { [K: string]: { id: string; order: number } } => {
  return keyBy(
    treeMapItems.filter(treeMapItem => treeMapItem.syllabusItem.type === ActiveSyllabusItemTypeDto.FOLDER)
      .map((treeMapItem, idx) => ({ id: treeMapItem.syllabusItem.id, order: idx })),
    item => item.id,
  );
};

export const scrollToTop = (): void => {
  const element = document.querySelector('.c-scm-page__content');
  if (element) {
    element.scrollTo(0, 0);
  }
};

export const convertMinutesToSeconds = (minutes: string): number => {
  if (!minutes) {
    return null;
  }
  return parseInt(minutes, 10) * 60;
};

export const getLearningDurationFromExternalEntity = (externalEntity: CatalogExternalEntityDtoParsed, catalogItem: RecContentItemDto): number | null => {
  if (!catalogItem || catalogItem.type === RecContentItemTypeDto.SIM_CHART) {
    return null;
  }
  if (catalogItem && catalogItem.type === RecContentItemTypeDto.OSMOSIS_VIDEO) {
    const duration = get(externalEntity, '_parsedData.videoDuration', '0:0') as string;
    return getOsmosisVideoDurationInSeconds(duration);
  }

  if (catalogItem.type === RecContentItemTypeDto.ADAPTIVE_LESSON) {
    const adaptiveLesson = externalEntity as CatalogAdaptiveLessonExternalEntityDto;
    if (has(adaptiveLesson, 'entity.attributes.hasDuration')) {
      return parseInt(adaptiveLesson.entity.attributes.hasDuration, 10);
    }
  }

  const estimate = get(externalEntity, '_parsedData.lessonDuration', get(externalEntity, '_parsedData.learningDuration', null));

  if (
    estimate
    && catalogItem
    && [
      RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY,
      RecContentItemTypeDto.SHERPATH_CASE_STUDY,
    ].includes(catalogItem.type)
  ) {
    return convertMinutesToSeconds(estimate);
  }

  return estimate;
};

export const getEbookLearningDurationFromCatalogItem = (catalogItem: RecContentItemDto, primaryTaxonomies: PrimaryTaxonomy[]) => {
  const isbn = getIsbnFromEbookCatalogItemId(catalogItem.id);
  const pageRangesFromEbookCatalogItemId = getPageRangesFromEbookCatalogItemId(catalogItem.id);
  const bookTaxonomy = getBookTaxonomy(isbn, primaryTaxonomies);
  const chapterPageRangeMap = getChapterPageRangesFromPageRanges(pageRangesFromEbookCatalogItemId, bookTaxonomy);
  const pageRanges = Object.values(chapterPageRangeMap).join(EbookPageRangeSeparators.CHUNK_SEPARATOR);
  return getTimeEstimateInSecondFromPageRanges(pageRanges);
};

export const getCatalogItemConfig = (
  contentItem: RecContentItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  evolveProducts: EvolveProductDto[],
  isOsmosisEnabled: boolean
): CatalogItemConfig => {
  if (!contentItem || !catalog) {
    return null;
  }
  let learningDuration: number;

  if (contentItem.type !== RecContentItemTypeDto.EBOOK_READING) {
    const externalEntity = getExternalEntity(contentItem, catalog);
    learningDuration = getLearningDurationFromExternalEntity(externalEntity, contentItem);
  } else {
    learningDuration = getEbookLearningDurationFromCatalogItem(contentItem, primaryTaxonomies);
  }

  return {
    title: getTitle(contentItem, catalog, primaryTaxonomies, null),
    subtitle: getSubtitle(contentItem, catalog, primaryTaxonomies, evolveProducts, null),
    includes: getContentItemIncludes(contentItem, catalog, isOsmosisEnabled),
    type: getSyllabusItemTypeFromCatalogItem(contentItem, catalog),
    evolveAssetType: getEvolveAssetType(contentItem, catalog),
    learningDuration,
    includedIn: getContentItemIncludedIn(contentItem, catalog, isOsmosisEnabled),
  };
};

export const getCatalogItemConfigMap = (
  catalog: CatalogWithExternalEntitiesDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  evolveProducts: EvolveProductDto[],
  isOsmosisEnabled: boolean
): CatalogItemConfigMap => {
  if (!catalog) {
    return {};
  }
  return catalog.catalog.data.reduce((map, item) => {
    const itemConfig: CatalogItemConfig = getCatalogItemConfig(item, catalog, primaryTaxonomies, evolveProducts, isOsmosisEnabled);
    return {
      ...map,
      [item.id]: itemConfig
    };
  }, {});
};

export const getRelatedProductsFromEvolve = (evolveProducts: EvolveProductDto[], ebook: EvolveProductDto): BundleMemberProductDto[] => {
  if (isEmpty(evolveProducts)) {
    return [];
  }

  let product: EvolveProductDto = null;
  evolveProducts.forEach(evolveProduct => {
    if (evolveProduct.components.some(component => (component.type === EvolveProductType.EBOOK && component.isbn === ebook.isbn))) {
      product = evolveProduct;
    }
  });

  if (isEmpty(product)) {
    return [];
  }

  if (!product.bundleMemberProduct || !product.bundleMemberProduct.length) {
    return [];
  }

  return product.bundleMemberProduct.filter((relatedProduct: BundleMemberProductDto) => {
    return Object.values(BundleMemberProductTypeKeyDto).includes(relatedProduct.productTypeKey as BundleMemberProductTypeKeyDto);
    // return relatedProduct.productTypeKey === BundleMemberProductTypeKey.BINDER_READY;
  });
};

export const getRelatedProductUrl = (
  userRole: string, user: UserDto,
  registeredToken: string,
  relatedProducts: BundleMemberProductDto[],
  promotionCode: string
): string => {

  const searchParams = {
    action: 'purchase',
    productCodes: JSON.stringify(relatedProducts.map(item => parseInt(item.isbn, 10))),
    referredBy: 'lms',
    role: isStudent(userRole) ? 'student' : 'faculty',
    email: user.emailAddress,
    pc: promotionCode,
    token: registeredToken
  };

  const urlSearchParams = new URLSearchParams(searchParams);
  const baseUrl = ServerConstants[ELSCommonConfig.appProfile].evolveCartURL;
  return `${baseUrl}?${urlSearchParams.toString()}`;
};

const getIsPublished = (
  recContentItem: RecContentItemDto,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  isFeatureFlagBasedBatchContentEnabled: boolean
): boolean => {

  if (!recContentItem) {
    return null;
  }

  if (!isFeatureFlagBasedBatchContentEnabled) {
    if (!recContentItem.attributes) {
      return null;
    }
    return recContentItem.attributes.isPublished;
  }

  const featureFlagFound = featureFlagsGrouped.find((featureFlag) => {
    if (featureFlag.featureName !== FEATURE_FLAG.CATALOG_ITEM_IS_PUBLISHED) {
      return false;
    }
    const foundGroup = featureFlag.groups.find((group) => {
      return recContentItem.id.includes(group);
    });
    return !!foundGroup;
  });

  if (!featureFlagFound) {
    return null;
  }

  if (featureFlagFound.featureValue === TRUE_VALUE) {
    return true;
  }

  if (featureFlagFound.featureValue === FALSE_VALUE) {
    return false;
  }

  return null;
};

const getPublishDate = (
  recContentItem: RecContentItemDto,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  isFeatureFlagBasedBatchContentEnabled: boolean
): string => {

  if (!recContentItem) {
    return null;
  }

  if (!isFeatureFlagBasedBatchContentEnabled) {
    if (!recContentItem.attributes) {
      return null;
    }
    return recContentItem.attributes.publishDate;
  }

  const featureFlagFound = featureFlagsGrouped.find((featureFlag) => {
    if (featureFlag.featureName !== FEATURE_FLAG.CATALOG_ITEM_PUBLISH_DATE) {
      return false;
    }
    const foundGroup = featureFlag.groups.find((group) => {
      return recContentItem.id.includes(group);
    });
    return !!foundGroup;
  });

  if (!featureFlagFound) {
    return null;
  }

  return featureFlagFound.featureValue;
};

export const isCatalogItemNotPublished = (
  recContentItem: RecContentItemDto,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  isBatchContentEnabled: boolean,
  isFeatureFlagBasedBatchContentEnabled: boolean
) => {
  // isBatchContentEnabled is here mostly to force engineers to check whether the mock feature is on
  if (!isBatchContentEnabled) {
    return false;
  }
  const isPublished = getIsPublished(recContentItem, featureFlagsGrouped, isFeatureFlagBasedBatchContentEnabled);
  return isPublished === false;
};

export const isCatalogItemRecentlyPublished = (
  recContentItem: RecContentItemDto,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  isBatchContentEnabled: boolean,
  isFeatureFlagBasedBatchContentEnabled: boolean
) => {
  // isBatchContentEnabled is here mostly to force engineers to check whether the mock feature is on
  if (!isBatchContentEnabled) {
    return false;
  }
  const isPublished = getIsPublished(recContentItem, featureFlagsGrouped, isFeatureFlagBasedBatchContentEnabled);
  const publishDate = getPublishDate(recContentItem, featureFlagsGrouped, isFeatureFlagBasedBatchContentEnabled);
  return isPublished === true && moment(publishDate).isAfter(moment().add(-RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS, 'days'));
};

export const isCatalogItemPublished = (
  recContentItem: RecContentItemDto,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  isBatchContentEnabled: boolean,
  isFeatureFlagBasedBatchContentEnabled: boolean
) => {
  // isBatchContentEnabled is here mostly to force engineers to check whether the mock feature is on
  if (!isBatchContentEnabled) {
    return false;
  }
  const isPublished = getIsPublished(recContentItem, featureFlagsGrouped, isFeatureFlagBasedBatchContentEnabled);
  return isPublished === true;
};

export const getUndismissedCatalogItems = (props: {
  catalog: CatalogWithExternalEntitiesDto;
  userHistory: EolsUserHistoryResponseDto[];
  courseSectionId: string;
  featureFlagsGrouped: FeatureFlagsGroupedDto[];
  userHistoryStateCompletedRequests: CoursewareUserHistoryStateKey[];
}): RecContentItemDto[] => {
  const {
    catalog,
    userHistory,
    courseSectionId,
    featureFlagsGrouped,
    userHistoryStateCompletedRequests,
  } = props;

  if (!userHistory) {
    return null;
  }

  if (!userHistoryStateCompletedRequests.includes(CoursewareUserHistoryStateKey.DISMISSED_NEW_CATALOG_ITEMS)) {
    return null;
  }

  const thisUserHistory = userHistory.sort((a, b) => {
    return a.id - b.id;
  }).find((userHistoryItem) => {
    return userHistoryItem.stateKey === CoursewareUserHistoryStateKey.DISMISSED_NEW_CATALOG_ITEMS
      && userHistoryItem.courseSectionId.toString() === courseSectionId;
  });

  const dismissedPublishedCatalogItemIds = thisUserHistory ? JSON.parse(thisUserHistory.stateInfo) : [];

  const isBatchContentEnabled = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.ENABLE_BATCH_CONTENT,
    courseSectionId
  ) : false;

  const isFeatureFlagBasedBatchContentEnabled = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.ENABLE_FEATURE_FLAG_BASED_BATCH_CONTENT,
    courseSectionId
  ) : false;

  return catalog.catalog.data
    .filter((recContentItem: RecContentItemDto) => {
      if (!recContentItem) {
        return false;
      }
      return isCatalogItemPublished(recContentItem, featureFlagsGrouped, isBatchContentEnabled, isFeatureFlagBasedBatchContentEnabled)
        && !dismissedPublishedCatalogItemIds.includes(recContentItem.id);
    }).map((recContentItem: RecContentItemDto) => {
      return {
        ...recContentItem,
        attributes: {
          contentId: recContentItem.attributes.contentId,
          isPublished: true,
          isbn: recContentItem.attributes.isbn,
          publishDate: recContentItem.attributes.publishDate ? recContentItem.attributes.publishDate : ''
        }
      };
    });
};

export const getUndismissedUpcomingCatalogItems = (props: {
  catalog: CatalogWithExternalEntitiesDto;
  userHistory: EolsUserHistoryResponseDto[];
  courseSectionId: string;
  featureFlagsGrouped: FeatureFlagsGroupedDto[];
  userHistoryStateCompletedRequests: CoursewareUserHistoryStateKey[];
}): RecContentItemDto[] => {
  const {
    catalog,
    userHistory,
    courseSectionId,
    featureFlagsGrouped,
    userHistoryStateCompletedRequests,
  } = props;

  if (!userHistory) {
    return null;
  }

  if (!userHistoryStateCompletedRequests.includes(CoursewareUserHistoryStateKey.DISMISSED_UPCOMING_CATALOG_ITEMS)) {
    return null;
  }

  const thisUserHistory = userHistory.sort((a, b) => {
    return a.id - b.id;
  }).find((userHistoryItem) => {
    return userHistoryItem.stateKey === CoursewareUserHistoryStateKey.DISMISSED_UPCOMING_CATALOG_ITEMS
      && userHistoryItem.courseSectionId.toString() === courseSectionId;
  });

  const dismissedPublishedCatalogItemIds = thisUserHistory ? JSON.parse(thisUserHistory.stateInfo) : [];

  const isBatchContentEnabled = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.ENABLE_BATCH_CONTENT,
    courseSectionId
  ) : false;

  const isFeatureFlagBasedBatchContentEnabled = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.ENABLE_FEATURE_FLAG_BASED_BATCH_CONTENT,
    courseSectionId
  ) : false;

  return catalog.catalog.data
    .filter((recContentItem: RecContentItemDto) => {
      if (!recContentItem) {
        return false;
      }
      return isCatalogItemNotPublished(recContentItem, featureFlagsGrouped, isBatchContentEnabled, isFeatureFlagBasedBatchContentEnabled)
        && !dismissedPublishedCatalogItemIds.includes(recContentItem.id);
    }).map((recContentItem: RecContentItemDto) => {
      return {
        ...recContentItem,
        attributes: {
          contentId: recContentItem.attributes.contentId,
          isPublished: false,
          isbn: recContentItem.attributes.isbn,
          publishDate: recContentItem.attributes.publishDate ? recContentItem.attributes.publishDate : ''
        }
      };
    });
};

export const getTaxonMap = (catalog: CatalogWithExternalEntitiesDto): Record<string, RecTaxonomyNodeDto> => {
  if (!catalog || !catalog.catalog || !catalog.catalog.included || !catalog.catalog.included.length) {
    return {};
  }
  return catalog.catalog.included.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.id]: cur
    };
  }, {});
};

export const getOsmosisTokenAndConfigPromise = (props: {
  isOsmosisVideosEnabled: boolean;
  catalog: CatalogWithExternalEntitiesDto;
  contentItem: RecContentItemDto;
  fetchOsmosisTokenAction: Function;
}): Promise<{
  osmosisToken: OsmosisTokenDto;
  osmosisIncludesConfigs: SherpathLessonOsmosisIncludesConfigs;
}> => {

  const {
    isOsmosisVideosEnabled,
    catalog,
    contentItem,
    fetchOsmosisTokenAction
  } = props;

  if (!isOsmosisVideosEnabled) {
    return Promise.resolve(null);
  }

  const osmosisIncludesConfigs = getSherpathLessonOsmosisIncludesConfigs(contentItem, catalog, isOsmosisVideosEnabled);
  const hasOsmosisVideo = osmosisIncludesConfigs && osmosisIncludesConfigs.length;

  if (!hasOsmosisVideo) {
    Promise.resolve(null);
  }
  return fetchOsmosisTokenAction().then((osmosisToken) => {
    return {
      osmosisToken,
      osmosisIncludesConfigs
    };
  });
};

export const isAnyCatalogItemFolderOpen = (collapsedFolderMap: Record<string, boolean>): boolean => {
  if (!collapsedFolderMap) {
    return true;
  }
  const vals = Object.values(collapsedFolderMap);
  if (!vals.length) {
    return true;
  }
  return vals.some((key) => {
    return !key;
  });
};
