import { createSelector } from 'reselect';
import { cwSelectors } from './courseware.selectors';
import { ActionMenuItemConfig } from '../../pages/course-plan/syllabus.models';
import {
  getActiveOrderedAddActionConfigs
} from '../../pages/catalog/catalog.utilities';
import {
  CoursewareStore,
  FilterStateType,
  ReduxPage,
  ReduxPageWithCatalog,
  ReduxPageWithFilter,
  ReduxPageWithPrimaryTaxonomies
} from './courseware.models';

const {
  getFeatureFlagsGrouped,
  getEvolveProducts,
  getCourseSectionId,
  getCatalog,
  getCwStore,
  getRoleId
} = cwSelectors;

export const makeGetActiveOrderedAddActionConfigs = (orderedItemOptions: ActionMenuItemConfig[]) => {
  return createSelector(
    [getFeatureFlagsGrouped, getEvolveProducts, getCourseSectionId, getRoleId, getCatalog],
    (featureFlagsGrouped, evolveProducts, courseSectionId, userRole, catalog) => {
      return getActiveOrderedAddActionConfigs(featureFlagsGrouped, orderedItemOptions, evolveProducts, courseSectionId, catalog, userRole).map((actionConfig) => {
        return actionConfig.action;
      });
    }
  );
};

const makeGetReduxPage = <T extends ReduxPage>(reduxPage: T) => (globalState): CoursewareStore[T] => getCwStore(globalState)[reduxPage];

export const makeGetCatalogInReduxPage = (reduxPage: ReduxPageWithCatalog) => {
  return createSelector(
    [makeGetReduxPage(reduxPage)],
    (page) => {
      return page.catalog;
    }
  );
};

export const makeGetPrimaryTaxonomiesInReduxPage = (reduxPage: ReduxPageWithPrimaryTaxonomies) => {
  return createSelector(
    [makeGetReduxPage(reduxPage)],
    (page) => {
      return page.primaryTaxonomies;
    }
  );
};

export const makeGetFilterStateInReduxPage = <T>(reduxPage: ReduxPageWithFilter, field: FilterStateType) => {
  return createSelector(
    makeGetReduxPage(reduxPage),
    (page) => {
      return page.filterState[field] as unknown as T;
    }
  );
};
