import get from 'lodash/get';
import reduce from 'lodash/reduce';
import moment from 'moment';
import {
  isEmpty,
  isNil,
} from 'lodash';
import { coursewareInitialState } from './courseware/courseware.constants';
import { CoursewareStore } from './courseware/courseware.models';
import {
  omitStateKeys,
  RootState
} from './redux.constants';
import {
  CatalogWithExternalEntitiesDto,
  OsmosisTokenDto,
  SherpathModuleExternalEntityDto,
} from '../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS } from '../pages/catalog/catalog.constants';
import {
  RecContentItemTypeDto,
} from '../apis/rec-gateway/rec-gateway.dtos';
import { stripNilProps } from '../utilities/common.utilities';
import { CourseSectionDto } from '../apis/eols-course-crud/eols-course-crud.dtos';
import { AssessmentSubmissionDto } from '../apis/eols-assessment-service/eols-assessment-service.dtos';
import institutions from '../constants/institutions.constants.json';
import {
  FeatureFlagDto,
  FeatureFlagsGroupedDto
} from '../apis/eols-features-api/eols-features-api.dtos';
import {
  SequenceConfigItem,
  SequenceMap
} from '../apis/ocs-api-service/ocs-api-service.dtos';
import {
  getSherpathModuleParsed,
  getTaxonMap
} from '../pages/catalog/catalog.utilities';

const filterNotFoundError = (errors: Error[]) => errors.filter(error => {
  if (get(error, 'response.status', '') === 404) {
    return false;
  }
  if (get(error, 'error.response.status', '') === 404) {
    return false;
  }
  if (get(error, 'response.statusCode', '') === 404) {
    return false;
  }
  return true;
});

const includesConflictError = (errors: Error[]) => errors.filter(error => {
  if (get(error, 'response.status', '') === 409) {
    return true;
  }
  if (get(error, 'error.response.status', '') === 409) {
    return true;
  }
  if (get(error, 'response.statusCode', '') === 409) {
    return true;
  }
  return false;
});

const omitProps = (rootState: RootState): RootState => {
  const coursewareState = reduce(rootState.coursewareState, (acc, cur, key) => {
    if (omitStateKeys[key]) {
      return acc;
    }
    return {
      ...acc,
      [key]: cur,
    };
  }, {}) as CoursewareStore;
  return {
    ...rootState,
    coursewareState,
  };
};

const omitPropsWithDefaultValue = (rootState: RootState): RootState => {
  const coursewareState = reduce(rootState.coursewareState, (acc, cur, key) => {
    if (coursewareInitialState[key] !== cur) {
      return {
        ...acc,
        [key]: cur,
      };
    }
    return acc;
  }, {}) as CoursewareStore;
  return {
    ...rootState,
    coursewareState,
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const addMockPublishAttribute = (catalog: CatalogWithExternalEntitiesDto): CatalogWithExternalEntitiesDto => {

  if (!catalog || !catalog.catalog || !catalog.catalog.data || !catalog.catalog.data.length) {
    return catalog;
  }

  const lessonItems = catalog.catalog.data.filter((contentItem) => {
    return [RecContentItemTypeDto.ADAPTIVE_LESSON, RecContentItemTypeDto.SHERPATH_LESSON].includes(contentItem.type);
  }).sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }).reduce((acc, cur, idx) => {
    if (idx % 10 === 0) {
      return {
        ...acc,
        notPublished: [...acc.notPublished, cur.id]
      };
    }
    if (idx % 11 === 0) {
      return {
        ...acc,
        publishedNew: [...acc.publishedNew, cur.id]
      };
    }
    if (idx % 12 === 0) {
      return {
        ...acc,
        publishedOld: [...acc.publishedOld, cur.id]
      };
    }
    return acc;
  }, {
    publishedOld: [],
    publishedNew: [],
    notPublished: []
  });

  return {
    ...catalog,
    catalog: {
      ...catalog.catalog,
      data: catalog.catalog.data.map((contentItem) => {
        let isPublished;
        let publishDate;
        if (lessonItems.notPublished.includes(contentItem.id)) {
          isPublished = 'false';
        } else if (lessonItems.publishedOld.includes(contentItem.id)) {
          isPublished = 'true';
          publishDate = moment()
            .add(-RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS, 'days')
            .add(-2, 'days')
            .toDate()
            .toISOString();
        } else if (lessonItems.publishedNew.includes(contentItem.id)) {
          isPublished = 'true';
          publishDate = moment()
            .add(-RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS, 'days')
            .add(2, 'days')
            .toDate()
            .toISOString();
        }
        return {
          ...contentItem,
          attributes: stripNilProps({
            ...contentItem.attributes,
            isPublished,
            publishDate
          })
        };
      })
    }
  };
};

const getEvolveResourcesFromCourseSection = (isbns: string[], vantageComponentIsbns: string[], currentCourse: CourseSectionDto) => {

  const _isbns = vantageComponentIsbns || isbns;

  if (isEmpty(_isbns) || isEmpty(currentCourse) || isEmpty(currentCourse.entitlements)) {
    return [];
  }

  return _isbns.reduce((_acc, isbn) => {
    return currentCourse.entitlements.reduce((acc, entitlement) => {
      if (isbn === entitlement.isbn) {
        return [...acc, JSON.parse(entitlement.data)];
      }
      return acc;
    }, _acc);
  }, []);
};

const shouldGetNewOsmosisToken = (osmosisTokenDto: OsmosisTokenDto) => {
  if (!osmosisTokenDto || !osmosisTokenDto.expires) {
    return true;
  }
  return moment.utc(osmosisTokenDto.expires).add({ hours: -10 }).isBefore(moment());
};

const getAssessmentSubmissionsMap = (
  assessmentSubmissionsResponses: {
    assignmentId: string;
    assessmentSubmissions: AssessmentSubmissionDto[];
  }[]
) => {
  if (!assessmentSubmissionsResponses || !assessmentSubmissionsResponses.length) {
    return {};
  }
  return assessmentSubmissionsResponses.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.assignmentId]: cur.assessmentSubmissions
    };
  }, {});
};

const getNormalizedInstitution = (userEmailDomain: string) => {
  let institution = 'Unknown';
  if (!userEmailDomain) {
    return institution;
  }
  const cleanedUserEmailDomain = userEmailDomain.trim().toLowerCase();
  institution = institutions[cleanedUserEmailDomain] || institution;
  return institution;
};

const addFeatureFlagWithGroup = (featureFlag: FeatureFlagDto, featureFlagsGrouped: FeatureFlagsGroupedDto[] = []): FeatureFlagsGroupedDto[] => {
  if (!featureFlag || isNil(featureFlag.group)) {
    return featureFlagsGrouped;
  }
  const isMatch = (flag: FeatureFlagsGroupedDto): boolean => {
    return flag.featureName === featureFlag.featureName
      && flag.featureValue === featureFlag.featureValue
      && flag.groups.length > 0;
  };

  const hasFlag = featureFlagsGrouped.some((flag) => {
    return isMatch(flag);
  });

  if (!hasFlag) {
    const newFlag: FeatureFlagsGroupedDto = {
      eolsApp: featureFlag.eolsApp,
      featureName: featureFlag.featureName,
      featureValue: featureFlag.featureValue,
      groups: [featureFlag.group]
    };
    return [
      ...featureFlagsGrouped,
      newFlag
    ];
  }
  return featureFlagsGrouped.reduce((acc, cur) => {
    if (isMatch(cur)) {
      const updatedFlag: FeatureFlagsGroupedDto = {
        ...cur,
        groups: [
          ...cur.groups,
          featureFlag.group
        ]
      };
      return [
        ...acc,
        updatedFlag
      ];
    }
    return [...acc, cur];
  }, []);
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getModuleSequenceMapFromCatalog = (catalog: CatalogWithExternalEntitiesDto): SequenceMap => {

  if (!catalog || !catalog.catalog || !catalog.catalog.data || !catalog.catalog.data.length) {
    return {};
  }

  const taxonMap = getTaxonMap(catalog);

  return catalog.catalog.data.reduce((acc, recContentItemDto) => {
    if (recContentItemDto.type !== RecContentItemTypeDto.SHERPATH_MODULE) {
      return acc;
    }
    return recContentItemDto.relationships.taxonomies.data.reduce((_acc, cur) => {

      if (!taxonMap[cur.id]) {
        return _acc;
      }
      const recTaxonomyNodeDto = taxonMap[cur.id];

      if (!recContentItemDto.attributes.isbn) {
        return _acc;
      }

      // Here we create a unique externEntity catalogItemId for each node instead of using recContentItemDto.id
      // This is only done for SHERPATH_MODULE externEntities because in this case we have an entity for each catalog item taxon mapping
      const catalogItemId = `${recContentItemDto.attributes.isbn}/${recTaxonomyNodeDto.id}`;

      const module = catalog.externalEntities.find((externalEntity) => {
        return externalEntity.catalogItemId === catalogItemId;
      });

      if (!module) {
        return _acc;
      }

      const parsed = getSherpathModuleParsed(module as SherpathModuleExternalEntityDto);

      if (!parsed || !parsed._parsedData || !parsed._parsedData.resourceVtwId) {
        return _acc;
      }

      const configItem: SequenceConfigItem = {
        sequenceDto: parsed._parsedData,
        taxon: recTaxonomyNodeDto
      };
      return {
        ..._acc,
        [parsed._parsedData.resourceVtwId]: configItem
      };
    }, acc);
  }, {});

};

export {
  filterNotFoundError,
  includesConflictError,
  omitProps,
  omitPropsWithDefaultValue,
  addMockPublishAttribute,
  getEvolveResourcesFromCourseSection,
  shouldGetNewOsmosisToken,
  getAssessmentSubmissionsMap,
  getNormalizedInstitution,
  addFeatureFlagWithGroup
};
