import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { ActiveSyllabusItemTypeDto, } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import {
  RecContentItemDto,
  RecTaxonomyNodeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';

export type ResourceStatusKey = 'added' | 'visible' | 'assigned';

export interface ResourceStatusValue {
  syllabusItem: SyllabusItemDto;
  parentItem: SyllabusItemDto;
}

export type SyllabusFolderLocationInfo = {
  destinationId: string;
  locationValue: string;
}

export type ResourceStatus = {
  [K in ResourceStatusKey]: ResourceStatusValue[];
};

export interface ResourceStatusMap {
  [K: string]: ResourceStatus;
}

export type CatalogItemConfig = {
  title: string;
  subtitle: string;
  includes: Partial<Record<ActiveSyllabusItemTypeDto, string[]>>;
  includedIn: Partial<Record<ActiveSyllabusItemTypeDto, string[]>>;
  type: ActiveSyllabusItemTypeDto;
  evolveAssetType: string;
  learningDuration: number;
}

export type CatalogItemConfigMap = Record<string, CatalogItemConfig>;

export enum FolderViewItemType {
  NODE = 'NODE',
  ITEM = 'ITEM',
  TYPE = 'TYPE',
}

export type CatalogItem = {
  node: RecTaxonomyNodeDto;
  item: RecContentItemDto;
  type: FolderViewItemType;
  key: string;
  id: string;
  config: CatalogItemConfig;
  level: number;
}

export enum EvolveResourceAssetType {
  INS_ANIMATIONS_VIDEOS = 'INS_ANIMATIONS_VIDEOS',
  INS_ANSWER_KEY = 'INS_ANSWER_KEY',
  INS_CASE_STUDIES = 'INS_CASE_STUDIES',
  INS_CHECKLIST = 'INS_CHECKLIST',
  INS_CURRICULAR_RESOURCES__ANNOUNCEMENTS = 'INS_CURRICULAR_RESOURCES__ANNOUNCEMENTS',
  INS_CURRICULAR_RESOURCES__CONTENT_UPDATES = 'INS_CURRICULAR_RESOURCES__CONTENT_UPDATES',
  INS_CURRICULAR_RESOURCES__CONVERSION_GUIDE = 'INS_CURRICULAR_RESOURCES__CONVERSION_GUIDE',
  INS_CURRICULAR_RESOURCES__CURRICULUM_GUIDES = 'INS_CURRICULAR_RESOURCES__CURRICULUM_GUIDES',
  INS_CURRICULAR_RESOURCES__CURRICULUM_MAP = 'INS_CURRICULAR_RESOURCES__CURRICULUM_MAP',
  INS_CURRICULAR_RESOURCES__GLOSSARY = 'INS_CURRICULAR_RESOURCES__GLOSSARY',
  INS_CURRICULAR_RESOURCES__LEARNING_OBJECTIVES = 'INS_CURRICULAR_RESOURCES__LEARNING_OBJECTIVES',
  INS_CURRICULAR_RESOURCES__NURSING_SKILLS_ONLINE_READING_ASSIGNMENTS = 'INS_CURRICULAR_RESOURCES__NURSING_SKILLS_ONLINE_READING_ASSIGNMENTS',
  INS_CURRICULAR_RESOURCES__OUTLINES_SUMMARIES = 'INS_CURRICULAR_RESOURCES__OUTLINES_SUMMARIES',
  INS_DOWNLOAD = 'INS_DOWNLOAD',
  INS_EAB_RESOURCE = 'INS_EAB_RESOURCE',
  INS_GENERAL_RESOURCES__CREDITS = 'INS_GENERAL_RESOURCES__CREDITS',
  INS_GENERAL_RESOURCES__OTHER_RESOURCES = 'INS_GENERAL_RESOURCES__OTHER_RESOURCES',
  INS_IMAGE_COLLECTION = 'INS_IMAGE_COLLECTION',
  INS_INSTRUCTOR_LED_ASSESSMENTS__CHAPTER_PRETESTS = 'INS_INSTRUCTOR_LED_ASSESSMENTS__CHAPTER_PRETESTS',
  INS_INSTRUCTOR_LED_ASSESSMENTS__QUESTION = 'INS_INSTRUCTOR_LED_ASSESSMENTS__QUESTION',
  INS_INSTRUCTOR_LED_ASSESSMENTS__QUESTIONS = 'INS_INSTRUCTOR_LED_ASSESSMENTS__QUESTIONS',
  INS_INSTRUCTOR_LED_ASSESSMENTS__TEST_BANK = 'INS_INSTRUCTOR_LED_ASSESSMENTS__TEST_BANK',
  INS_INTERRELATED_CONCEPTS = 'INS_INTERRELATED_CONCEPTS',
  INS_POWERPOINT_SLIDES = 'INS_POWERPOINT_SLIDES',
  INS_TEACH__2_7_HANDOUTS = 'INS_TEACH__2_7_HANDOUTS',
  INS_TEACH__2_7_LESSON_PLANS = 'INS_TEACH__2_7_LESSON_PLANS',
  INS_TEACH__2_7_PRETESTS = 'INS_TEACH__2_7_PRETESTS',
  INS_TEACH__2_7_STUDENT_HANDOUTS = 'INS_TEACH__2_7_STUDENT_HANDOUTS',
  INS_TEACH__2_7_WORKBOOK_ANSWER_KEY = 'INS_TEACH__2_7_WORKBOOK_ANSWER_KEY',
  INS_TEACH__FOR_NURSES = 'INS_TEACH__FOR_NURSES',
  STU_ACTIVITIES__BLOG = 'STU_ACTIVITIES__BLOG',
  STU_ACTIVITIES__GAMES = 'STU_ACTIVITIES__GAMES',
  STU_ACTIVITIES__REFERENCE_CARDS = 'STU_ACTIVITIES__REFERENCE_CARDS',
  STU_ACTIVITIES__TOOLBOX = 'STU_ACTIVITIES__TOOLBOX',
  STU_ANIMATIONS_VIDEOS = 'STU_ANIMATIONS_VIDEOS',
  STU_ANSWER_KEY = 'STU_ANSWER_KEY',
  STU_CARE_PLANNING = 'STU_CARE_PLANNING',
  STU_CARE_PLANNING__CONCEPTUAL = 'STU_CARE_PLANNING__CONCEPTUAL',
  STU_CASE_STUDIES = 'STU_CASE_STUDIES',
  STU_CHECKLISTS = 'STU_CHECKLISTS',
  STU_GENERAL_RESOURCES__ANNOUNCEMENTS = 'STU_GENERAL_RESOURCES__ANNOUNCEMENTS',
  STU_GENERAL_RESOURCES__APPENDICES = 'STU_GENERAL_RESOURCES__APPENDICES',
  STU_GENERAL_RESOURCES__APPLICATIONS = 'STU_GENERAL_RESOURCES__APPLICATIONS',
  STU_GENERAL_RESOURCES__AUDIO_GLOSSARY = 'STU_GENERAL_RESOURCES__AUDIO_GLOSSARY',
  STU_GENERAL_RESOURCES__CLINICAL_REFERENCES = 'STU_GENERAL_RESOURCES__CLINICAL_REFERENCES',
  STU_GENERAL_RESOURCES__CONTENT_UPDATES = 'STU_GENERAL_RESOURCES__CONTENT_UPDATES',
  STU_GENERAL_RESOURCES__CREDITS = 'STU_GENERAL_RESOURCES__CREDITS',
  STU_GENERAL_RESOURCES__GLOSSARY = 'STU_GENERAL_RESOURCES__GLOSSARY',
  STU_GENERAL_RESOURCES__HELPFUL_TIPS_FOR_LEARNERS = 'STU_GENERAL_RESOURCES__HELPFUL_TIPS_FOR_LEARNERS',
  STU_GENERAL_RESOURCES__NURSING_PROCESS = 'STU_GENERAL_RESOURCES__NURSING_PROCESS',
  STU_GENERAL_RESOURCES__OTHER_RESOURCES = 'STU_GENERAL_RESOURCES__OTHER_RESOURCES',
  STU_GENERAL_RESOURCES__WEBLINKS = 'STU_GENERAL_RESOURCES__WEBLINKS',
  STU_KEY_POINTS = 'STU_KEY_POINTS',
  STU_MOBILE_RESOURCES = 'STU_MOBILE_RESOURCES',
  STU_NURSING_SKILLS = 'STU_NURSING_SKILLS',
  STU_REVIEW_QUESTIONS__FOR_EXAMINATION_PREPARATION = 'STU_REVIEW_QUESTIONS__FOR_EXAMINATION_PREPARATION',
  STU_REVIEW_QUESTIONS__NCLEX_STYLE_REVIEW_QUESTIONS = 'STU_REVIEW_QUESTIONS__NCLEX_STYLE_REVIEW_QUESTIONS',
  STU_REVIEW_QUESTIONS__PRACTICE_EXAMS = 'STU_REVIEW_QUESTIONS__PRACTICE_EXAMS',
  STU_REVIEW_QUESTIONS__REVIEW_QUESTIONS = 'STU_REVIEW_QUESTIONS__REVIEW_QUESTIONS',
  STU_REVIEW_QUESTIONS__REVIEW_QUESTIONS_AND_ANSWERS = 'STU_REVIEW_QUESTIONS__REVIEW_QUESTIONS_AND_ANSWERS',
  STU_REVIEW_QUESTIONS__REVIEW_QUESTIONS_PDF = 'STU_REVIEW_QUESTIONS__REVIEW_QUESTIONS_PDF',
  STU_TUTORIALS = 'STU_TUTORIALS',
}
