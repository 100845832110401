import { AssignmentDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

export enum SortByValue {
  COURSE_PLAN = 'coursePlan',
  TYPE_ASC = 'type_asc',
  TYPE_DESC = 'type_desc',
  TITLE_ASC = 'title_asc',
  TITLE_DESC = 'title_desc',
  DUE_DATE_ASC = 'dueDate_asc',
  DUE_DATE_DESC = 'dueDate_desc',
  AVAILABLE_DATE_ASC = 'availableDate_asc',
  AVAILABLE_DATE_DESC = 'availableDate_desc',
  ASSIGNMENT_GRADE_TYPE_ASC = 'assignmentGradeType_asc',
  ASSIGNMENT_GRADE_TYPE_DESC = 'assignmentGradeType_desc',
}

export enum BulkEditSettingsField {
  AVAILABLE_FROM = 'AVAILABLE_FROM',
  AVAILABLE_FROM_ORIGINAL_DATE_INPUT = 'AVAILABLE_FROM_ORIGINAL_DATE_INPUT',
  DUE = 'DUE',
  DUE_ORIGINAL_DATE_INPUT = 'DUE_ORIGINAL_DATE_INPUT',
  GRADING = 'GRADING',
  TITLE = 'TITLE',
  SORT_BY = 'SORT_BY',
  PUSH_AVAILABILITY_DATES = 'PUSH_AVAILABILITY_DATES',
  AVAILABILITY_WEEKS = 'AVAILABILITY_WEEKS',
  AVAILABILITY_DAYS = 'AVAILABILITY_DAYS',
  AVAILABILITY_HOURS = 'AVAILABILITY_HOURS',
  AVAILABILITY_MINUTES = 'AVAILABILITY_MINUTES',
  PUSH_DUE_DATES = 'PUSH_DUE_DATES',
  DUE_WEEKS = 'DUE_WEEKS',
  DUE_DAYS = 'DUE_DAYS',
  DUE_HOURS = 'DUE_HOURS',
  DUE_MINUTES = 'DUE_MINUTES',
}

export enum ExtendDatesValue {
  TO_A_LATER_DATE = 'TO_A_LATER_DATE',
  TO_AN_EARLIER_DATE = 'TO_AN_EARLIER_DATE'
}

export type BulkEditorError = {
  message: string;
  fields: BulkEditSettingsField[];
}

export type AssignmentError = {
  assignment: AssignmentDto;
  errors: BulkEditorError[];
};

export enum BulkEditorCloseRef {
  HEADER = 'HEADER',
  FOOTER = 'FOOTER'
}
