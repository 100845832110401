import { get } from 'lodash';
import { CatalogWithExternalEntitiesDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { RecContentItemDto } from '../../apis/rec-gateway/rec-gateway.dtos';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { EvolveProductDto } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { AssignmentDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  getSubtitle,
  getTitle
} from '../catalog/catalog.utilities';
import { MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH } from '../course-plan/syllabus.constants';

export const getInteractiveReviewAssignmentInfo = (
  catalog: CatalogWithExternalEntitiesDto,
  contentItem: RecContentItemDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  evolveProducts: EvolveProductDto[]
): {
  assignmentGoals: AssignmentDto['assignmentGoals'];
  title: AssignmentDto['title'];
  subtitle: AssignmentDto['subtitle'];
  contentId: AssignmentDto['contentId'];
} => {
  const contentId = get(contentItem, 'attributes.contentId', null);
  return {
    assignmentGoals: [{
      id: null,
      goal: 100,
      vtwId: contentId,
      text: null,
    }],
    title: contentItem ? getTitle(contentItem, catalog, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : '',
    subtitle: contentItem ? getSubtitle(contentItem, catalog, primaryTaxonomies, evolveProducts, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : '',
    contentId
  };
};
