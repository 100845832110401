import React from 'react';
import cx from 'classnames';
import { ELSMenu } from '@els/els-component-menu-react';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import {
  OrderedFolderActionMenuOptions,
  OrderedSyllabusItemActions,
  SyllabusItemActionConfigMap
} from './syllabus.constants';
import { ActiveSyllabusItemTypeDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import {
  canDeleteAssignment,
  getEABTitle,
  isAlreadyUnassigned,
  showSyllabusItemPerformanceReport,
  SyllabusItemDictionaryItem
} from './syllabus.utilities';
import { _ReactChildren } from '../../models/common.models';
import {
  ActionMenuItemConfig,
  ActionMenuItemType
} from './syllabus.models';
import IconWithText from '../../components/icon-with-text/IconWithText.component';
import { AssignmentDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { EvolveProductDto } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { getActiveOrderedAddActionConfigs } from '../catalog/catalog.utilities';
import { convertStringForClass } from '../../utilities/common.utilities';
import { FeatureFlagsGroupedDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import { CatalogWithExternalEntitiesDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { Messages } from '../../translations/message.models';
import {
  isAssignmentGraded,
  isAssignmentStarted
} from '../../components/assignment-editor/assignment-editor.utilities';
import { SyllabusItemAction } from '../../constants/content-type.constants';
import { ELSTooltip } from '../../components/els.components';
import { messages } from '../../utilities/app.utilities';

const iconTextActions = IconWithText({
  iconName: 'ellipsis',
  iconPrefix: 'gizmo',
  children: 'Actions'
});

export interface SyllabusItemActionMenuProps {
  assignment: AssignmentDto;
  featureFlagsGrouped: FeatureFlagsGroupedDto[];
  courseSectionId: string;
  isMoveEnabled: boolean;
  isBatchEditMode: boolean;
  isSelected: boolean;
  level: number;
  syllabusNestLevelLimit: number;
  syllabusItem: SyllabusItemDto;
  onActionButtonClick: Function;
  evolveProducts: EvolveProductDto[];
  catalog: CatalogWithExternalEntitiesDto;
  userRole: string;
  messages: Messages;
  syllabusItemDictionaryItem: SyllabusItemDictionaryItem;
  enableDeepLink: boolean;
}

export const byRemovingExtraDivider = (acc, cur: ActionMenuItemConfig, index: number, arr) => {
  if (index === 0 && cur.type === ActionMenuItemType.DIVIDER) {
    return acc;
  }
  if (cur !== arr[index - 1]) {
    acc.push(cur);
  }
  return acc;
};

const byHasAbilityToAddSubFolder = (props: SyllabusItemActionMenuProps) => (item: ActionMenuItemConfig): boolean => {
  if (props.level >= props.syllabusNestLevelLimit) {
    return item.action !== SyllabusItemAction.ADD_A_FOLDER;
  }
  return true;
};

const filterCreateDeepLinkAction = (props: SyllabusItemActionMenuProps) => (item: ActionMenuItemConfig): boolean => {
  if (!getBooleanFromGroupFeatureFlagWithFallbackToGlobal(props.featureFlagsGrouped, FEATURE_FLAG.ENABLE_DEEP_LINKING, props.courseSectionId)
    && SyllabusItemAction.CREATE_DEEP_LINK === item.action) {
    return false;
  }
  if (!props.enableDeepLink && SyllabusItemAction.CREATE_DEEP_LINK === item.action) {
    return false;
  }
  return true;
};

const filterDevAddAssignmentAction = (props: SyllabusItemActionMenuProps) => (item: ActionMenuItemConfig): boolean => {
  if (!getBooleanFromGroupFeatureFlagWithFallbackToGlobal(props.featureFlagsGrouped, FEATURE_FLAG.IS_DEV_ASSIGNMENT_MODAL_ENABLED, props.courseSectionId)
    && item.action === SyllabusItemAction.DEV_ADD_ASSIGNMENT) {
    return false;
  }
  return true;
};

const filterMoveActions = (props: SyllabusItemActionMenuProps) => (item: ActionMenuItemConfig): boolean => {
  if (!props.isMoveEnabled && SyllabusItemAction.MOVE === item.action) {
    return false;
  }
  return true;
};

const filterBatchActions = (props: SyllabusItemActionMenuProps) => (item: ActionMenuItemConfig): boolean => {
  if ((!props.isBatchEditMode || !props.isSelected) && [
    SyllabusItemAction.BULK_MOVE_REORDER,
    SyllabusItemAction.BULK_REMOVE,
    SyllabusItemAction.BULK_UNASSIGN,
    SyllabusItemAction.BULK_EDIT_SETTING,
    SyllabusItemAction.BULK_MAKE_VISIBLE_NOW,
  ].indexOf(item.action) !== -1) {
    return false;
  }
  return true;
};

const filterUnassignAction = (props: SyllabusItemActionMenuProps) => (item: ActionMenuItemConfig): boolean => {
  if (SyllabusItemAction.UNASSIGN !== item.action) {
    return true;
  }

  if (!props.syllabusItem) {
    return false;
  }

  return props.syllabusItem.type !== ActiveSyllabusItemTypeDto.FOLDER;
};

const filterViewPerformanceAction = (props: SyllabusItemActionMenuProps) => (item: ActionMenuItemConfig): boolean => {
  if (!showSyllabusItemPerformanceReport(props.syllabusItem, props.assignment) && item.action === SyllabusItemAction.VIEW_PERFORMANCE) {
    return false;
  }
  return true;
};

const getMenuItemConfigs = (props: SyllabusItemActionMenuProps): ActionMenuItemConfig[] => {

  if (props.syllabusItem.type !== ActiveSyllabusItemTypeDto.FOLDER) {
    const nonFolderActionsfilterConditions = [
      filterDevAddAssignmentAction(props),
      filterMoveActions(props),
      filterBatchActions(props),
      filterUnassignAction(props),
      filterViewPerformanceAction(props),
      filterCreateDeepLinkAction(props),
    ];

    return OrderedSyllabusItemActions.filter((item) => {
      return nonFolderActionsfilterConditions.every(condition => condition(item));
    }).reduce(byRemovingExtraDivider, []);
  }

  const folderActionsFilterConditions = [
    filterBatchActions(props),
    byHasAbilityToAddSubFolder(props),
  ];

  const actions = getActiveOrderedAddActionConfigs(
    props.featureFlagsGrouped,
    OrderedFolderActionMenuOptions,
    props.evolveProducts,
    props.courseSectionId,
    props.catalog,
    props.userRole
  );

  return actions
    .filter((item) => {
      return folderActionsFilterConditions.every(condition => condition(item));
    })
    .reduce(byRemovingExtraDivider, []);
};

export interface SyllabusItemActionMenuItem {
  customContent: _ReactChildren;
  syllabusItem: SyllabusItemDto;
  action: SyllabusItemAction;
  notClickable?: boolean;
  customClasses?: string;
  tabIndex?: number;
}

export const generateMenuItem = (menuItemConfig: ActionMenuItemConfig, props: SyllabusItemActionMenuProps): SyllabusItemActionMenuItem => {
  if (menuItemConfig.type === ActionMenuItemType.DIVIDER) {
    return {
      customContent: <div className="c-els-divider c-els-divider--1o2" />,
      syllabusItem: null,
      action: menuItemConfig.action,
      notClickable: true,
      tabIndex: -1,
    };
  }

  const syllabusItemActionConfig = SyllabusItemActionConfigMap[menuItemConfig.action];

  let isDisabled = false;
  let { title } = syllabusItemActionConfig;

  if (
    menuItemConfig.action === SyllabusItemAction.REMOVE
    && props.assignment
    && !canDeleteAssignment(props.assignment)
  ) {
    isDisabled = true;
    title = props.messages.WARNING_MESSAGE_FOR_UNABLE_TO_REMOVE_WHEN_ASSIGNMENT_STARTED;
  }

  if (
    menuItemConfig.action === SyllabusItemAction.UNASSIGN
    && (isAlreadyUnassigned(props.assignment) || (isAssignmentStarted(props.assignment) && isAssignmentGraded(props.assignment)))
  ) {
    isDisabled = true;
    title = isAlreadyUnassigned(props.assignment)
      ? props.messages.UNABLE_TO_UNASSIGN_BECAUSE_ALREADY_UNASSIGNED
      : props.messages.UNABLE_TO_UNASSIGN_BECAUSE_GRADED_STARTED;
  }

  const menuItemCustomContent = (
    <button
      type="button"
      data-qe={`course-plan-menu-item-${convertStringForClass(syllabusItemActionConfig.title)}`}
      title={
        menuItemConfig.action === SyllabusItemAction.ADD_ASSESSMENT_BUILDER ? getEABTitle(props.evolveProducts, messages) : title
      }
      key={`${syllabusItemActionConfig.title}`}
      className={
        cx('u-els-debuttonize c-els-link c-els-link--with-icon qe-scm-course-plan-menu-item', {
          'c-els-link--cw-disabled': isDisabled
        })
      }>
      <IconWithText
        iconPrefix={syllabusItemActionConfig.iconPrefix}
        iconName={syllabusItemActionConfig.iconName}>
        <span className="c-els-link__text">{syllabusItemActionConfig.title}</span>
      </IconWithText>
    </button>
  );

  if (menuItemConfig.action === SyllabusItemAction.ADD_EXTERNAL_LINK) {
    return {
      customContent: (
        <ELSTooltip
          id="add-external-link-tooltip"
          dialogAriaLabel="Add External Link Tooltip"
          showClose={false}
          showWhenHover
          content={(
            <div
              className="u-els-max-view-width-1o3 u-els-max-view-width-1o1@mobile">{props.messages.EXTERNAL_LINK_TOOLTIP}
            </div>
          )}
        >
          {menuItemCustomContent}
        </ELSTooltip>
      ),
      syllabusItem: props.syllabusItem,
      action: menuItemConfig.action,
      tabIndex: -1,
    };
  }
  return {
    customContent: menuItemCustomContent,
    syllabusItem: props.syllabusItem,
    action: menuItemConfig.action,
    tabIndex: -1,
  };
};

export const getMenuItems = (props: SyllabusItemActionMenuProps): SyllabusItemActionMenuItem[] => {
  return getMenuItemConfigs(props).map((menuItemConfig: ActionMenuItemConfig): SyllabusItemActionMenuItem => {
    return generateMenuItem(menuItemConfig, props);
  });
};

const handleMenuToggle = (e) => {
  if (e && e.stopPropagation) {
    e.stopPropagation();
  }
};

export const SyllabusItemActionMenu: React.FC<SyllabusItemActionMenuProps> = (props: SyllabusItemActionMenuProps) => {
  return (
    <ELSMenu
      buttonId={props.syllabusItem && `action-button-${props.syllabusItem.id}`}
      buttonType="blank"
      isAnchorized
      className="u-els-display-block qe-syllabus-item-action-menu"
      buttonClassName="u-els-display-block"
      onItemClick={props.onActionButtonClick}
      menuItems={getMenuItems(props)}
      buttonAriaLabel={`Action menu for ${props.syllabusItemDictionaryItem.title}`}
      onMenuToggle={handleMenuToggle}>
      <strong>
        {iconTextActions}
      </strong>
    </ELSMenu>
  );
};

export default React.memo(SyllabusItemActionMenu);
