import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import {
  isArray,
  isNil,
  isObject,
} from 'lodash';
import pLimit from 'p-limit';
import { ServerConstants } from '../components/app/server.constants';
import { CatalogEvolveResourceExternalEntityDtoParsed, } from '../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  getBooleanFromGroupFeatureFlagWithFallbackToGlobal,
  getFeatureFlagGroups
} from './featureFlag.utilities';
import { FEATURE_FLAG } from '../apis/eols-features-api/eols-features-api.constants';
import { FeatureFlagsGroupedDto } from '../apis/eols-features-api/eols-features-api.dtos';

export const isInstructor = userRole => userRole === ELSCommonUIConstants.userType.Instructor;

export const isStudent = userRole => userRole === ELSCommonUIConstants.userType.Student;

export const flattenTree = (array, nestProp: string, level = 1) => {
  return array.reduce((acc, cur) => {
    if (cur[nestProp] && cur[nestProp].length) {
      return [...acc, cur, ...flattenTree(cur[nestProp], nestProp, level + 1)];
    }
    return [...acc, cur];
  }, []);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stripNilProps = (obj: Record<string, any>): Record<string, any> => {
  const copy = {
    ...obj
  };
  Object.keys(copy).forEach((key) => {
    if (isNil(copy[key])) {
      delete copy[key];
    }
  });
  return copy;
};

export const isOnlyNumber = (str: string): boolean => {
  const pattern = /^[0-9]*$/gm;
  return pattern.test(str);
};

export const convertStringForClass = (string: string): string => {
  return string
    .toLowerCase()
    .replace(new RegExp(' ', 'g'), '-')
    .replace(new RegExp(/\/|\(|\)/g, 'g'), '');
};

export const mapToIds = (x) => x.id;

export const joinListCommaSeparated = (items: string[]): string => {
  if (!items || !items.length) {
    return '';
  }
  if (items.length === 1) {
    return items[0];
  }
  return items.reduce((acc, cur, idx, arr) => {
    if (idx === 0) {
      return cur;
    }
    if (idx + 1 === arr.length) {
      return `${acc} & ${cur}`;
    }
    return `${acc}, ${cur}`;
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stitchArrays = (arrays: any[][], currentIndex = 0, output: any[] = []): any[] => {

  if (arrays.every((array) => {
    return !array.length;
  })) {
    return output;
  }

  const nextIndex = currentIndex + 1 === arrays.length ? 0 : currentIndex + 1;

  if (!arrays[currentIndex].length) {
    return stitchArrays(arrays, nextIndex, output);
  }

  const nextItem = arrays[currentIndex].shift();
  return stitchArrays(arrays, nextIndex, [...output, nextItem]);
};

export const truncateTitle = (title: string, maxLength: number): string => {
  if (isNil(maxLength) || !title || !title.length || title.length <= maxLength) {
    return title;
  }
  return `${title.substring(0, maxLength - 3)}...`;
};

export const getObjectPropsArray = (propVal, acc = [], path = ''): {
  prop: string;
  val: unknown;
}[] => {

  if (isArray(propVal)) {
    return propVal.reduce((_acc, cur, idx) => {
      return getObjectPropsArray(cur, _acc, path ? `${path}[${idx}]` : `[${idx}]`);
    }, acc);
  }

  if (!isObject(propVal)) {
    return [
      ...acc,
      {
        prop: path,
        val: propVal
      }
    ];
  }

  return Object.keys(propVal).reduce((_acc, prop) => {

    if (isArray(propVal[prop]) || isObject(propVal[prop])) {
      return getObjectPropsArray(propVal[prop], _acc, path ? `${path}.${prop}` : prop);
    }

    return [
      ..._acc,
      {
        prop: path ? `${path}.${prop}` : prop,
        val: propVal[prop]
      }
    ];
  }, acc);
};

export const filterBySearchQuery = (searchQuery: string, wordsToSearch: string[]): boolean => {
  if (!searchQuery || !searchQuery.length) {
    return true;
  }
  if (!wordsToSearch || !wordsToSearch.length) {
    return false;
  }
  const searchQueryWordList = searchQuery.toLowerCase().split(' ').map(item => item.trim());
  if (!searchQueryWordList || !searchQueryWordList.length) {
    return true;
  }
  const wordsToSearchNormalized = wordsToSearch.map(item => item.toLowerCase().trim());
  return searchQueryWordList.every(searchWord => {
    if (searchWord === '') {
      return true;
    }
    return wordsToSearchNormalized.some(word => {
      return word.includes(searchWord);
    });
  });
};

export const openEvolveResource = (evolveResource: CatalogEvolveResourceExternalEntityDtoParsed) => {

  if (!evolveResource || !evolveResource._parsedData) {
    return;
  }

  if (evolveResource._parsedData.eolsContentPath) {
    window.open(`${ServerConstants[ServerConstants.DataSource].contentBaseURL}${evolveResource._parsedData.eolsContentPath}`, '_blank');
  } else {
    window.open(evolveResource._parsedData.linkUrl, '_blank');
  }
};

export const isTrueValue = (val: string): boolean => {
  if (isNil(val)) {
    return false;
  }
  return val.toString().toLowerCase() === 'true';
};

export const removeOuterQuotes = (str: string): string => {
  return str.replace(/^'|'$/g, '');
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleLargePromiseSetWithLimiter = (
  itemsToUpdate: any[],
  updateHandler: (item: any) => Promise<any>,
  incrementProgressIndicator: (incNum: number) => void,
  setProgressIndicatorValues: (progressIndicatorValues: { completed: number; total: number }) => void,
  requestLimit: number
): Promise<any[]> => {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  const limit = pLimit(requestLimit);
  setProgressIndicatorValues({ completed: 0, total: itemsToUpdate.length });
  return Promise.all(
    itemsToUpdate.map(item => {
      return limit(() => {
        return updateHandler(item)
          .catch()
          .finally(() => {
            incrementProgressIndicator(1);
          });
      });
    })
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const handleLargePromiseSetBatchRecursive = (
  itemsToUpdate: any[],
  updateHandler: (item: any) => Promise<any>,
  batchSize: number,
  incrementProgressIndicator: (incNum: number) => void
): Promise<any> => {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  if (!itemsToUpdate.length) {
    return Promise.resolve([]);
  }
  const itemsToUpdateNow = itemsToUpdate.slice(0, batchSize);
  const itemsToUpdateNext = itemsToUpdate.slice(batchSize);
  return Promise.all(
    // eslint-disable-next-line sonarjs/no-identical-functions
    itemsToUpdateNow.map(item => {
      return updateHandler(item)
        .catch()
        .finally(() => {
          incrementProgressIndicator(1);
        });
    })
  ).then((currentUpdatedItems) => {
    return handleLargePromiseSetBatchRecursive(itemsToUpdateNext, updateHandler, batchSize, incrementProgressIndicator)
      .then((nextUpdatedItems) => {
        return currentUpdatedItems.concat(nextUpdatedItems);
      });
  });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleLargePromiseSetWithBatch = (
  itemsToUpdate: any[],
  updateHandler: (item: any) => Promise<any>,
  batchSize: number,
  incrementProgressIndicator: (incNum: number) => void,
  setProgressIndicatorValues: (progressIndicatorValues: { completed: number; total: number }) => void
): Promise<any> => {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  setProgressIndicatorValues({ completed: 0, total: itemsToUpdate.length });
  return handleLargePromiseSetBatchRecursive(itemsToUpdate, updateHandler, batchSize, incrementProgressIndicator);
};

export const getIsChatBotEnabled = (
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  courseSectionId: string
): boolean => {

  const isKillChatBot = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.KILL_CHATBOT,
    courseSectionId,
  ) : false;

  const isChatBotEnabled = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.ENABLE_CHATBOT,
    courseSectionId,
    true
  ) : false;

  return !isKillChatBot && isChatBotEnabled;
};

export const isGoDirectToAIChatBot = (
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  courseSectionId: string,
  isbns: string,
): boolean => {

  if (!featureFlagsGrouped || !courseSectionId || !isbns) {
    return false;
  }

  const isKillChatBot = getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.KILL_CHATBOT,
    courseSectionId,
  );

  if (isKillChatBot) {
    return false;
  }

  const featureIsbns = getFeatureFlagGroups(featureFlagsGrouped, FEATURE_FLAG.IS_DIRECT_CHATBOT_ISBN);

  if (!featureIsbns || !featureIsbns.length) {
    return false;
  }

  return featureIsbns.some((isbn) => {
    return isbns.includes(isbn);
  });
};

export const getIsSubTopicFiltersEnabled = (
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  courseSectionId: string
): boolean => {
  return featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.IS_EVOLVE_SUB_TOPIC_FILTERS_ENABLED,
    courseSectionId,
  ) : false;
};
